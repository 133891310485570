import api from "./api";
import { recuperarCache, salvarCache } from "./cacheHandler";

export const getTipoDocumento = async () => {
    try {

        // Verificando o cache
        const tipoDocumento = recuperarCache('tipoDocumento');
        if (tipoDocumento) {
            return tipoDocumento;
        }

        const response = await api.get("/tipoDocumento_");
        
        // Cacheando
        salvarCache('tipoDocumento', response.data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}