import api from "./api";

const endpoint = '/imagemChamado_';

export const saveAllImagemChamado = async (imagensChamado) => {
    try {
        // Enviando as imagensChamado
        const response = await api.post(endpoint + "/all", imagensChamado);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const getImagensByChamado = async (chamado) => {
    try {
        const response = await api.post(endpoint + "/byChamado", chamado);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}