import './FormEditarChamado.css'
import { FaUser,FaCalendar } from "react-icons/fa";
import { EditTicketContext } from '../../context/EditTicketContext';
import { useContext } from 'react';
import HeaderContainers from '../HeaderContainers/HeaderContainers';
import { localDateTimeArrToStringFormatado } from '../../utils/DataUtils';

function FormEditarChamado() {

    const { ticketData } = useContext(EditTicketContext);

    if(ticketData === undefined) {
        return <div>Loading...</div>;
    }

    const temImagens = ticketData.imagens.length > 0;
    const textoNumeroChamado = "#" + ticketData.idChamado;
    const semTituloText = "SEM TÍTULO"
    const semTitulo = ticketData.assunto === undefined || ticketData.assunto === "";

    return (
        <div className="visualizar-chamado-container-maior">
            <HeaderContainers extra={textoNumeroChamado} cinza={true} texto={semTitulo ? semTituloText : ticketData.assunto} />

            <div className="container-visualizar-chamado">
                <form>
                    {/* Cabeçalho do chamado */}
                    <div className="informacoes-adicionais-chamado">
                        <div className="subs-do-editar-chamado">
                            <p className="texto-info-adicional">
                                <FaUser className="icone-usuario" />{ticketData.usuarioResponsavel.nome}
                            </p>
                            <p className="texto-info-adicional">
                                <FaCalendar className="icone-data" />{ticketData.dataCriacao !== undefined? localDateTimeArrToStringFormatado(ticketData.dataCriacao): ''}
                            </p>
                        </div>
                        
                        <hr className="linha-divisora-info-adicional-vis-chamado" />
                    </div>

                    <div className="container-input-chamado">
                        <p className="descricao-do-chamado">{ticketData.descricao}</p>

                        {temImagens 
                            && <ImagensSelecionadasVisChamado
                                    imagens={[ticketData.imagens]}
                                    onRemove={null}
                                />}
                    </div>
                </form>
            </div>
        </div>
    )
}

const ImagensSelecionadasVisChamado = ({
    imagens,
    onRemove,
}) => {

    const direcionaParaImagem = (url) => {
        window.open(url, "_blank");
    }

    return (
        <div className="imagens-adicionadas-pelo-usuario-vis-chamado">
            {imagens[0].map((imagem, index) => (
                <div key={index} className="container-imagem-selecionada-vis-chamado">
                    <img className="imagem-selecionada-pelo-usuario-chamado-vis-chamado" onClick={() => {direcionaParaImagem(imagem.enderecoImagem)}} src={imagem.enderecoImagem} alt="Arquivo Selecionado" />
                </div>
            ))}
        </div>
    )
}

export default FormEditarChamado