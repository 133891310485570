import HeaderContainers from '../HeaderContainers/HeaderContainers';
import InputPadrao from '../InputPadrao/InputPadrao';
import BotaoPadrao from '../BotaoPadrao/BotaoPadrao';
import InputSelectPadrao from '../InputSelectPadrao/InputSelectPadrao';
import './FormCadastroDepartamento.css'
import { MdBadge } from "react-icons/md";
import { DepartamentoContext } from '../../context/DepartamentoContext';
import { useContext, useEffect, useState } from 'react';
import { RiErrorWarningFill } from "react-icons/ri";
import { getSituacaoCadastro } from '../../services/situacaoCadastroService';
import { SiGlassdoor } from "react-icons/si";
import NotifyUtils from '../../utils/NotifyUtils';
import { useAuth } from '../../services/AuthContext';
import Loading from '../Loading/Loading';

function FormCadastroDepartamento() {

    const [ loading, setLoading ] = useState(true);

    const { user, setPodeCadastrarUsuario } = useAuth();
    const { handleChange, handleSubmit, handleCancelling } = useContext(DepartamentoContext);

    if(!user.podeCadastrarDepartamento) {
        window.location.href = '/departamentos';
    }

    const textoCarregando = "carregando...";
    const textoConfirmar = "confirmar";
    const [botaoDesabilitado, setBotaoDesabilitado] = useState(false);
    const [mensagemBotaoConfirmar, setMensagemBotaoConfirmar] = useState(textoConfirmar);

    const [ nomeDepartamentoValido, setNomeDepartamentoValido ] = useState(true);
    const [ siglaValida, setSiglaValida ] = useState(true);
    const [ situacaoCadastroValida, setSituacaoCadastroValida ] = useState(true);

    const [situacaoCadastro, setSituacaoCadastro] = useState(null);
    useEffect(() => {
        async function fetchSituacao() {
            const start = Date.now();
            const situacaoCadastroFetched = await getSituacaoCadastro();

            // Forçar um tempo mínimo de exibição de loading (ex: 500ms)
            const elapsed = Date.now() - start;
            const delay = Math.max(500 - elapsed, 0);
    
            setTimeout(() => {
                setSituacaoCadastro(situacaoCadastroFetched);
                setLoading(false);
            }, delay);
        }

        fetchSituacao();
    }, []);
    
    const handleCancelar = () => {
        handleCancelling();
    }

    let situacoesCadastro = [];
    if (situacaoCadastro !== null) {
        situacoesCadastro = situacaoCadastro;
    }

    const clickBotaoConfirmar = async (e) => {
        // Assim que confirmar, desabilita o botão e muda o texto para "carregando..."
        e.preventDefault();
        setBotaoDesabilitado(true);
        setMensagemBotaoConfirmar(textoCarregando);

        // Faz o envio de informações para o back caso esteja tudo certo com os campos, se não tiver, retorna os campos inválidos
        const retornoRequest = await handleSubmit();
        const erroCustom = retornoRequest.success === undefined;

        if(retornoRequest === null) {
            NotifyUtils.info("Cadastrando...");
            return;
        }

        if(!retornoRequest.success && !erroCustom) {
            NotifyUtils.erro(retornoRequest.message);
        } else if(retornoRequest.success) {
            NotifyUtils.sucesso(retornoRequest.message);
            NotifyUtils.sucesso("Redirecionando...");

            setNomeDepartamentoValido(true);
            setSiglaValida(true);
            setSituacaoCadastroValida(true);

            setTimeout(() => {
                handleCancelling();
                setBotaoDesabilitado(false);
                setMensagemBotaoConfirmar(textoConfirmar);
            }, 3500);

            setPodeCadastrarUsuario(true);
            return;
        } else if(erroCustom) {
            NotifyUtils.erro(retornoRequest.message);

            // Atualiza status dos campos inválisdos
            const camposInvalidos = retornoRequest.camposInvalidos;

            setNomeDepartamentoValido(camposInvalidos.nomeValido);
            setSiglaValida(camposInvalidos.siglaValida);
            setSituacaoCadastroValida(camposInvalidos.situacaoValida);
        }

        setBotaoDesabilitado(false);
        setMensagemBotaoConfirmar(textoConfirmar);
    }

    return (
        <div className="container-cadastrar-departamento">
            {loading && <Loading />}
            <div className="container-geral-cadastro-departamento">
                <HeaderContainers texto="cadastro de departamento" />

                <div className="formulario-cadastrar-departamento">
                    <form>
                        <div className="inputs-principais-cadastro-departamento">
                            <InputPadrao
                                onChange={handleChange}
                                titulo={"Nome do Departamento"}
                                obrigatorio={true}
                                name={"nome-departamento"}
                                className={"nome-departamento-cad"}
                                placeholder={"Insira o nome do departamento (ex: T.I., Suporte, Desenvolvimento)"}
                                inputPara={"nomeDepartamento"}
                                icone={<MdBadge />}
                                comErro={!nomeDepartamentoValido}
                            />

                            <InputPadrao
                                onChange={handleChange}
                                className={"sigla-departamento-cad"}
                                titulo={"SIGLA"}
                                obrigatorio={true}
                                name={"sigla-departamento"}
                                placeholder={"Insira a sigla do departamento (ex: TI, SUP, DEV)"}
                                inputPara={"sigla"}
                                icone={<SiGlassdoor />}
                                comErro={!siglaValida}
                            />

                            <InputSelectPadrao
                                onChange={handleChange}
                                className={"situacao-cadastro-departamento-cad"}
                                titulo={"Situação do Cadastro"}
                                obrigatorio={true}
                                icone={<RiErrorWarningFill />}
                                name={"situacao-cadastro"}
                                placeholder={"Selecione a situação"}
                                inputPara={"situacaoCadastro"}
                                options={situacoesCadastro}
                                comErro={!situacaoCadastroValida}
                            />
                        </div>

                        <div className="botoes-cadastro-departamento">
                            <BotaoPadrao
                                acaoDeClicar={handleCancelar}
                                texto={"Cancelar"}
                                cancelar={true}
                            />
                            <BotaoPadrao
                                acaoDeClicar={clickBotaoConfirmar}
                                texto={mensagemBotaoConfirmar}
                                confirmar={true}
                                disabled={botaoDesabilitado}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default FormCadastroDepartamento;