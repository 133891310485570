import './FormCadastroUsuario.css'
import HeaderContainers from '../HeaderContainers/HeaderContainers';
import InputPadrao from '../InputPadrao/InputPadrao';
import InputSelectPadrao from '../InputSelectPadrao/InputSelectPadrao';
import { IoMdMail } from "react-icons/io";
import { FaUser } from "react-icons/fa";
import { IoDocumentText, c } from "react-icons/io5";
import { RiErrorWarningFill } from "react-icons/ri";
import InputSenha from '../InputSenha/InputSenha';
import { useContext, useEffect, useState } from 'react';
import { UsuarioContext } from '../../context/UsuarioContext';
import { getSituacaoCadastro } from '../../services/situacaoCadastroService';
import BotaoPadrao from '../BotaoPadrao/BotaoPadrao';
import { getPerfilUsuario } from '../../services/perfilUsuarioService';
import { VscUngroupByRefType } from "react-icons/vsc";
import { getDepartamentoEmpresa } from '../../services/departamentoEmpresaService';
import NotifyUtils from '../../utils/NotifyUtils';
import { useAuth } from '../../services/AuthContext';
import Loading from '../Loading/Loading';

function FormCadastroUsuario() {

    const { user } = useAuth();

    const [ loading, setLoading ] = useState(true);

    const { handleChange, handleSubmit, handleCancelling } = useContext(UsuarioContext);

    if(!user.podeCadastrarUsuario) {
        window.location.href = '/usuarios';
    }

    const textoCarregando = "carregando...";
    const textoConfirmar = "confirmar";
    const [botaoDesabilitado, setBotaoDesabilitado] = useState(false);
    const [mensagemBotaoConfirmar, setMensagemBotaoConfirmar] = useState(textoConfirmar);

    const [ nomeValido, setNomeValido ] = useState(true);
    const [ emailValido, setEmailValido ] = useState(true);
    const [ senhaValida, setSenhaValida ] = useState(true);
    const [ confirmarSenhaValida, setConfirmarSenhaValida ] = useState(true);
    const [ cpfValido, setCpfValido ] = useState(true);
    const [ situacaoCadastroValida, setSituacaoCadastroValida ] = useState(true);
    const [ perfilUsuarioValido, setPerfilUsuarioValido ] = useState(true);
    const [ departamentoValido, setDepartamentoValido ] = useState(true);

    const handleCancelar = () => {
        handleCancelling();
    }

    const [departamento, setDepartamento] = useState(null);
    const [perfilUsuario, setPerfilUsuario] = useState(null);
    const [situacaoCadastro, setSituacaoCadastro] = useState(null);
    useEffect(() => {
        async function fetchSituacao() {
            const situacaoCadastroFetched = await getSituacaoCadastro();
            setSituacaoCadastro(situacaoCadastroFetched);
        }

        async function fetchUsuarios() {
            const perfilUsuario = await getPerfilUsuario();
            setPerfilUsuario(perfilUsuario);
        }

        async function fetchDepartamento() {
            const start = Date.now();
            const departamento = await getDepartamentoEmpresa();

            // Forçar um tempo mínimo de exibição de loading (ex: 500ms)
            const elapsed = Date.now() - start;
            const delay = Math.max(500 - elapsed, 0);
    
            setTimeout(() => {
                setDepartamento(departamento);
                setLoading(false);
            }, delay);
        }

        fetchSituacao();
        fetchUsuarios();
        fetchDepartamento();
    }, []);

    let situacoesCadastro = [];
    let perfisUsuarios = [];
    let departamentos = [];

    if (situacaoCadastro !== null) {
        situacoesCadastro = situacaoCadastro;
    }

    if (perfilUsuario !== null) {
        let perfisVisiveis = perfilUsuario;
        if(perfilUsuario.length === 3) {
            perfisVisiveis.shift();
        }
        perfisUsuarios = perfisVisiveis;
    }

    if (departamento !== null) {
        departamentos = departamento;
    }

    const clickBotaoConfirmar = async (e) => {
        // Assim que confirmar, desabilita o botão e muda o texto para "carregando..."
        e.preventDefault();
        setBotaoDesabilitado(true);
        setMensagemBotaoConfirmar(textoCarregando);

        // Faz o envio de informações para o back caso esteja tudo certo com os campos, se não tiver, retorna os campos inválidos
        const retornoRequest = await handleSubmit();
        const erroCustom = retornoRequest.success === undefined;

        if(retornoRequest === null) {
            NotifyUtils.info("Cadastrando...");
            return;
        }

        if(!retornoRequest.success && !erroCustom) {
            NotifyUtils.erro(retornoRequest.message);
        } else if(retornoRequest.success) {
            NotifyUtils.sucesso(retornoRequest.message);

            setNomeValido(true);
            setEmailValido(true);
            setSenhaValida(true);
            setConfirmarSenhaValida(true);
            setCpfValido(true);
            setSituacaoCadastroValida(true);
            setPerfilUsuarioValido(true);
            setDepartamentoValido(true);

            NotifyUtils.sucesso("Redirecionando...");

            setTimeout(() => {
                handleCancelling();
                setBotaoDesabilitado(false);
                setMensagemBotaoConfirmar(textoConfirmar);
            }, 3500);
            return;
        } else if(erroCustom) {
            NotifyUtils.erro(retornoRequest.message);

            // Atualiza status dos campos inválisdos
            const camposInvalidos = retornoRequest.camposInvalidos;

            setNomeValido(camposInvalidos.nomeValido);
            setEmailValido(camposInvalidos.emailValido);
            setSenhaValida(camposInvalidos.senhaValida);
            setConfirmarSenhaValida(camposInvalidos.confirmarSenhaValida);
            setCpfValido(camposInvalidos.cpfValido);
            setSituacaoCadastroValida(camposInvalidos.situacaoCadastroValida);
            setPerfilUsuarioValido(camposInvalidos.perfilUsuarioValido);
            setDepartamentoValido(camposInvalidos.departamentoValido);
        }

        setBotaoDesabilitado(false);
        setMensagemBotaoConfirmar(textoConfirmar);
    }

    return (
        <div className="container-cadastrar-usuario">
            {loading && <Loading />}
            <div className="container-geral-cadastro-usuario">
                <HeaderContainers texto="cadastro de usuário" />

                <div className="formulario-cadastrar-nova-usuario">
                    <form>
                        <div className="container-principal-infos-usuario">
                            <div className="container-info-principal-usuario">
                                <div className="container-nome-email-cadastro-usuarios">
                                    <InputPadrao
                                        titulo="NOME"
                                        icone={<FaUser />}
                                        obrigatorio={true}
                                        name={"nome-usuario"}
                                        onChange={handleChange}
                                        placeholder={"Insira o nome do usuário."}
                                        inputPara={"nome"}
                                        comErro={!nomeValido}
                                    />
                                    <InputPadrao
                                        titulo="E-MAIL"
                                        icone={<IoMdMail />}
                                        obrigatorio={true}
                                        onChange={handleChange}
                                        name={"email-usuario"}
                                        placeholder={"Insira o email do usuário."}
                                        inputPara={"email"}
                                        comErro={!emailValido}
                                    />
                                    <InputPadrao
                                        titulo="NÚMERO DO CPF"
                                        icone={<IoDocumentText />}
                                        name={"cpf-usuario"}
                                        placeholder={"Insira o número do documento."}
                                        onChange={handleChange}
                                        mask="cpf"
                                        inputPara={"cpf"}
                                        comErro={!cpfValido}
                                    />
                                </div>
                            </div>

                            <div className="container-tipo-usuario-cadastro-usuario">
                                <InputSelectPadrao
                                    titulo={"TIPO USUÁRIO"}
                                    icone={<VscUngroupByRefType />}
                                    name={"tipo-usuario"}
                                    onChange={handleChange}
                                    placeholder={"Insira o tipo do usuário"}
                                    inputPara={"perfilUsuario"}
                                    options={perfisUsuarios}
                                    obrigatorio={true}
                                    comErro={!perfilUsuarioValido}
                                />

                                <InputSelectPadrao
                                    titulo={"DEPARTAMENTO"}
                                    icone={<IoDocumentText />}
                                    name={"departamento"}
                                    onChange={handleChange}
                                    placeholder={"Insira o departamento"}
                                    inputPara={"departamento"}
                                    options={converteDptoParaSelect(departamentos)}
                                    extra={departamentos}
                                    obrigatorio={true}
                                    comErro={!departamentoValido}
                                />

                                <InputSelectPadrao
                                    titulo="SITUAÇÃO DO CADASTRO"
                                    icone={<RiErrorWarningFill />}
                                    name={"situacao-cadastro"}
                                    onChange={handleChange}
                                    placeholder={"Selecione a situação."}
                                    options={situacoesCadastro}
                                    obrigatorio={true}
                                    inputPara={"situacaoCadastro"}
                                    comErro={!situacaoCadastroValida}
                                />
                            </div>
                        </div>

                        <div className="container-senha-cadastro-usuario">
                            <div className="titulo-container-senha">
                                <h3 className="titulo-senha-cadastro-usuario">SENHA</h3>
                                <hr className="divisor-top-container" />
                            </div>

                            <div className="container-senha-confirma-senha">
                                <InputSenha
                                    placeholder={"SENHA"}
                                    tipoInput={"senha"}
                                    onChange={handleChange}
                                    inputPara={"senha"}
                                    comErro={!senhaValida}
                                />
                                <InputSenha
                                    placeholder={"CONFIRMAR SENHA"}
                                    tipoInput={"confirmarSenha"}
                                    onChange={handleChange}
                                    inputPara={"confirmarSenha"}
                                    comErro={!confirmarSenhaValida}
                                />
                            </div>
                        </div>
                    </form>

                    <div className="container-botoes-cadastro-usuario">
                        <BotaoPadrao
                            texto={"CANCELAR"}
                            cancelar={true}
                            acaoDeClicar={handleCancelar}
                        />

                        <BotaoPadrao
                            texto={mensagemBotaoConfirmar}
                            confirmar={true}
                            acaoDeClicar={clickBotaoConfirmar}
                            disabled={botaoDesabilitado}
                        />
                    </div>

                </div>
            </div>
        </div>
    )
}

const converteDptoParaSelect = (departamento) => {
    return departamento.map((departamentoEmpresa) => {
        const dpto = departamentoEmpresa.departamento;
        return {
            id: dpto.idDepartamento,
            descricao: dpto.nomeDepartamento
        }
    });
}

export default FormCadastroUsuario;