import api from "./api";
import { recuperarCache, salvarCache } from "./cacheHandler";

export const getPerfilUsuario = async () => {

    // Verificando o cache
    const perfisUsuario = recuperarCache('perfilUsuario');
    if (perfisUsuario) {
        return perfisUsuario;
    }

    try {
        const response = await api.get("/perfilUsuario_");

        if(response.data.length === 3) {
            response.data.shift();
        }
        
        // Salvando no cache
        salvarCache('perfilUsuario', response.data);

        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}