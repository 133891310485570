import './InputPadrao.css';
import CampoObrigatorio from '../CampoObrigatorio/CampoObrigatorio';
import { useState } from 'react';
import { cnpjMask, cpfMask, mascaraCnpj, mascaraCpf } from '../../utils/MaskUtils';

function InputPadrao({
  className,
  onChange, 
  titulo, 
  obrigatorio, 
  icone,
  name, 
  placeholder, 
  inputPara,
  mask,
  comErro,
  valorInicial
  }) {

    const [valor, setValor] = useState(valorInicial !== undefined ? retornaValorComMascara(valorInicial, mask) : '');

    const handleInputChange = (e) => {
      let novoValor = e.target.value;
      let novoValorFormatado = '';
      
      novoValorFormatado = retornaValorComMascara(novoValor, mask);
      setValor(novoValorFormatado);

      if(onChange) {
        onChange(inputPara, novoValorFormatado) 
      }
    }

  return (
    <div className={`container-input-padrao ${name} ${className}`}>

      <div className="container-label-input-padrao">
        {titulo && (
          <div className="principais-input-padrao">
            {icone && icone}
            <label htmlFor={name}> {titulo} </label>
          
            {(obrigatorio) && (
              <div className="container-obrigatorio-input-padrao">
                <CampoObrigatorio />
              </div>
            )}
          </div>
        )}
      </div>

      <input 
        type="text" 
        className={`input-padrao-estilo ${comErro ? "erro-invalido" : ""}`}
        onChange={(e) => {handleInputChange(e)}}
        value={valor}
        id={name} 
        name={name} 
        placeholder={placeholder}/> 
    </div>
  )
}

const retornaValorComMascara = (valor, mascara) => {

  if(mascara === undefined) {
    return valor;
  }

  if(mascara.toLowerCase() === mascaraCpf) {
    return cpfMask(valor);
  } else if (mascara.toLowerCase() === mascaraCnpj) {
    return cnpjMask(valor);
  }
}

export default InputPadrao;