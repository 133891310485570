import api from "./api";

const endpoint = "/usuario_";

export const createUsuario = async (usuario) => {
    try {
        const response = await api.post(endpoint, usuario, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response.data;
    } catch (error) {
        if (error.response) {
            const { status, data } = error.response;
            if (status === 409) {
                return data;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }
}

export const getUsuarios = async () => {
    try {
        const response = await api.get(endpoint);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}

export const getUsuarioById = async (idUsuario) => {
    try {
        const response = await api.get(endpoint + `/${idUsuario}`);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const updateUsuario = async (usuario) => {
    try {
        const response = await api.put(endpoint + `/${usuario.idUsuario}`, usuario, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response.data;
    } catch (error) {
        if (error.response) {
            const { status, data } = error.response;
            if (status === 409) {
                return data;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }
}

export const getUsuarioByDepartamentoEmpresaList = async (listaDeptoEmpresa) => {
    try {
        const response = await api.post(endpoint + "/byDepartamentoEmpresaList", [listaDeptoEmpresa], {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}


