import FiltroDepartamentoContainer from '../FiltroDepartamentoContainer/FiltroDepartamentoContainer';
import ListViewDepartamento from '../ListViewDepartamento/ListViewDepartamento';
import './DepartamentoContainer.css';

function DepartamentoContainer() {
  return (
    <div className="container">
        <FiltroDepartamentoContainer />
        <ListViewDepartamento />
    </div>
  )
}

export default DepartamentoContainer;