import './ChamadoList.css';
import { GiPaintBucket } from "react-icons/gi";
import Checkbox from '../Checkbox/Checkbox';
import { FaFolderOpen } from "react-icons/fa";

const ChamadoList = ({
    idChamado,
    chamado,
    tipoListaChamado,
    filhoUnico}) => {

    const { departamentoEmpresa } = chamado;

    const irParaChamado = () => {
        window.location.href = `/visualizar-chamado/${idChamado}`;
    }

    return (
        <div onClick={irParaChamado} id={filhoUnico ? 'filho-unico' : ''} className={`chamado-container-principal ${tipoListaChamado}`}>
            <div className="info-principal-chamado">
                <div className="container-todas-informacoes">
                    <div className="checkbox-container-sozinho">
                        <Checkbox idChamado={idChamado} className="checkbox-chamado" />
                    </div>

                    <div className="div-complementar-todas-informacoes">
                        <div className="nome-e-numero-chamado-container">
                            <h4 className="nome-chamado">{chamado.assunto}</h4>
                            <p className="numero-chamado">#{idChamado}</p>
                        </div>
                        <div className="container-info-adicional-chamado">
                            <InfoAdicionalChamado icon={<GiPaintBucket />} content={departamentoEmpresa.departamento.nomeDepartamento} />
                            <InfoAdicionalChamado icon={<FaFolderOpen />} content={"Sistema"} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const InfoAdicionalChamado = ({ icon, content, classNameCustom }) => {
    return (
        <div className={`info-adicional-chamado ${classNameCustom}`}>
            {icon}
            <p>{content}</p>
        </div>
    )
}

export default ChamadoList;