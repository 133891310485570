import CustomSelectAdvanced from '../CustomSelectAdvanced/CustomSelectAdvanced'
import './ConfigEditarChamado.css'
import { useContext, useEffect, useState } from 'react';
import { EditTicketContext } from '../../context/EditTicketContext';
import HeaderContainers from '../HeaderContainers/HeaderContainers';
import { getStatus } from '../../services/statusService';
import BotaoPadrao from '../BotaoPadrao/BotaoPadrao';
import { useAuth } from '../../services/AuthContext';
import NotifyUtils from '../../utils/NotifyUtils';

function ConfigEditarChamado() {

    const { auth } = useAuth();

    const { ticketData, 
        handleCancelling, 
        usuariosDisponiveis, 
        departamentosDisponiveis, 
        handleChangeDynamic, 
        handleChange, 
        handleSubmit, 
        podeEditarEsseChamado} = useContext(EditTicketContext);

    const [status, setStatus] = useState(null);

    const textoConfirmar = "confirmar edição";
    const textoCarregando = "carregando...";
    const [botaoDesabilitado, setBotaoDesabilitado] = useState(false);
    const [mensagemBotaoConfirmar, setMensagemBotaoConfirmar] = useState(textoConfirmar);
    const [podeEscolher, setPodeEscolher] = useState(true);

    useEffect(() => {
        getStatus().then((status) => {
            setStatus(status);
        }).catch((error) => {
            console.error(error);
        });
    }, []);

    const [departamentoEmpresas, setDepartamentoEmpresas] = useState(departamentosDisponiveis);
    useEffect(() => {
        setDepartamentoEmpresas(departamentosDisponiveis);
    }, [departamentosDisponiveis]);

    const [usuarios, setUsuarios] = useState(usuariosDisponiveis);
    useEffect(() => {
        setUsuarios(usuariosDisponiveis);
    }, [usuariosDisponiveis]);

    let estadosChamados = [];
    let usuariosResponsaveis = [];
    let departamentos = [];
    
    if (status !== null) {
        estadosChamados = status;
    } else {
        return <div>Loading...</div>;
    }

    if(usuarios !== null) {
        usuariosResponsaveis = usuarios;
    } else {
        return <div>Loading...</div>;
    }

    if(departamentoEmpresas !== null) {
        departamentos = departamentoEmpresas;
    } else {
        return <div>Loading...</div>;
    }

    if(ticketData === null) {
        return <div>Loading...</div>;
    }

    const clickBotaoConfirmar = async (e) => {
        // Assim que confirmar, desabilita o botão e muda o texto para "carregando..."
        e.preventDefault();
        setBotaoDesabilitado(true);
        setMensagemBotaoConfirmar(textoCarregando);

        // Faz o envio de informações para o back caso esteja tudo certo com os campos, se não tiver, retorna os campos inválidos
        const retornoRequest = await handleSubmit();

        if(retornoRequest === null) {
            NotifyUtils.info("Nenhuma informação foi alterada!");
            setPodeEscolher(true);
            return;
        }

        const erroCustom = retornoRequest.success === undefined;
    
        if(!retornoRequest.success && !erroCustom) {
            NotifyUtils.erro(retornoRequest.message);
        } else if(retornoRequest.success) {
            NotifyUtils.sucesso(retornoRequest.message);

            setTimeout(() => {
                setBotaoDesabilitado(false);
                setMensagemBotaoConfirmar(textoConfirmar);
                setPodeEscolher(true);
            }, 2500);
            return;
        } else if(erroCustom) {
            NotifyUtils.erro(retornoRequest.message);
        }

        setPodeEscolher(true);
        setBotaoDesabilitado(false);
        setMensagemBotaoConfirmar(textoConfirmar);
    };

    return (
        <div className="configuracoes-chamado-container">
            <HeaderContainers texto="configurações" />
            <div className="container-selects-configuracao">

                <CustomSelectAdvanced
                    required={true}
                    tipoSelect={"status"}
                    opcoes={estadosChamados}
                    titulo={"ESTADO DO CHAMADO"}
                    handleChange={handleChange}
                    extra={estadosChamados}
                    tipoExtra={"status"}
                    opcaoInicial={true}
                    defaultOption={ticketData.status ? ticketData.status.descricao : undefined}
                    naoAtualiza={true}
                    podeEscolher={podeEscolher && podeEditarEsseChamado}
                />

                <CustomSelectAdvanced
                    required={true}
                    tipoSelect={"departamentoEmpresa"}
                    opcoes={separaDepartamentoEmpresa(departamentos)}
                    titulo={"DEPARTAMENTO"}
                    handleChange={handleChange}
                    extra={departamentos}
                    tipoExtra={"departamento"}
                    opcaoInicial={true}
                    defaultOption={ticketData.departamentoEmpresa ? ticketData.departamentoEmpresa.departamento.nomeDepartamento : undefined}
                    podeEscolher={podeEscolher && podeEditarEsseChamado}
                />

                <CustomSelectAdvanced
                    required={true}
                    tipoSelect={"usuarioResponsavelAtual"}
                    opcoes={separaUsuario(usuariosResponsaveis)}
                    titulo={`RESPONSÁVEL`}
                    handleChange={handleChange}
                    tipoExtra={"usuario"}
                    extra={usuariosResponsaveis}
                    opcaoInicial={true}
                    defaultOption={ticketData.usuarioResponsavelAtual ? ticketData.usuarioResponsavelAtual.nome : undefined}
                    podeEscolher={podeEscolher && podeEditarEsseChamado}
                />

            </div>

            <div className="botoes-config-container">
                <BotaoPadrao
                    acaoDeClicar={handleCancelling}
                    texto={"Voltar"}
                    cancelar={true}
                />
                <BotaoPadrao
                    acaoDeClicar={clickBotaoConfirmar}
                    texto={mensagemBotaoConfirmar}
                    confirmar={true}
                    disabled={!podeEditarEsseChamado || !podeEscolher || botaoDesabilitado}
                />
            </div>

        </div>
    );
}

const separaDepartamentoEmpresa = (listaDepartamentoEmpresa) => {
    let departamentos = [];

    if(listaDepartamentoEmpresa === undefined) {
        return departamentos;
    }

    for (let index = 0; index < listaDepartamentoEmpresa.length; index++) {
        const departamentoEmpresa = listaDepartamentoEmpresa[index];
        departamentos.push({ 
            descricao: departamentoEmpresa.departamento.nomeDepartamento,
            identificador: departamentoEmpresa.idDepartamentoEmpresa});
    }

    return departamentos;
}

const separaUsuario = (listaUsuarios) => {
    let usuarios = [];

    if(listaUsuarios === undefined) {
        return usuarios;
    }

    for (let index = 0; index < listaUsuarios.length; index++) {
        const usuario = listaUsuarios[index];
        usuarios.push({ 
            descricao: usuario.nome,
            identificador: usuario.idUsuario});
    }

    return usuarios;
}

export default ConfigEditarChamado;