import SituacaoCadastro from '../SituacaoCadastro/SituacaoCadastro';
import './ListViewDepartamento.css';
import { IoIosWarning } from "react-icons/io";
import { HiPencil } from "react-icons/hi";
import { useLocation } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { getDepartamentos } from '../../services/departamentoService';
import { DepartamentoFilterContext } from '../../context/DepartamentoFilterContext';
import Loading from '../Loading/Loading';

function ListViewDepartamento() {

    const [ loading, setLoading ] = useState(true);

    const { departamentoFilter, filtrar, setFiltrar, verificaFiltroVazio } = useContext(DepartamentoFilterContext);

    const [ departamentos, setDepartamentos ] = useState([]);
    const [ departamentosFiltrados, setDepartamentosFiltrados ] = useState([]);
    const [ filtrou, setFiltrou ] = useState(false);

    useEffect(() => {
        async function fetchDepartamentos() {
            const start = Date.now();
            const departamentosFetched = await getDepartamentos();

            // Forçar um tempo mínimo de exibição de loading (ex: 500ms)
            const elapsed = Date.now() - start;
            const delay = Math.max(500 - elapsed, 0);
    
            setTimeout(() => {
                setDepartamentos(departamentosFetched);
                setLoading(false);
            }, delay);
        }

        fetchDepartamentos();
    }, []);

    useEffect(() => {
        if(filtrar) {
            aplicarFiltro();
            setFiltrar(false);
        }
    }, [filtrar]);

    const aplicarFiltro = () => {
        let tempDepartamentos = [];

        if (departamentoFilter === undefined) return;

        if (verificaFiltroVazio()) {
            setFiltrou(false);
            setFiltrar(false);
            return;
        }

        departamentos.forEach((departamento) => {
            // Define tudo como lowerCase para facilitar a comparação
            const nomeComparacao = departamento.nomeDepartamento.toLowerCase();

            const nomeInclui = (nomeComparacao.includes(departamentoFilter.nomeDepartamento) && departamentoFilter.nomeDepartamento !== '') || departamentoFilter.nomeDepartamento === '';
            const situacaoCadastroIgual = (departamento.situacaoCadastro.idSituacaoCadastro === departamentoFilter.situacaoCadastro && departamentoFilter.situacaoCadastro !== '') || departamentoFilter.situacaoCadastro === '';

            // Verifica se o filtro está presente em algum dos campos
            if (nomeInclui
                && situacaoCadastroIgual) {
                tempDepartamentos.push(departamento);
            }
        });

        setFiltrou(true);
        setDepartamentosFiltrados(tempDepartamentos);
    };

    const handleEditarDepartamento = (idDepartamento) => {
        // Função que direciona para a página de edição de departamento
        window.location.href = `/editar-departamento/${idDepartamento}`;
    }

    return (
        <div>
            {loading && <Loading />}
            <div className="quantitativo-resultados-encontrados-list-view-departamentos">
                <span className="quantidade-departamentos-encontradas">{departamentos.length}</span>
                <p className="texto-resultado-departamentos-encontradas">Resultados Encontrados</p>
            </div>

            {departamentos.length == 0 
                ? (
                    <div className="container-list-view-departamento-nao-encontrado">
                        <NenhumEncontrada />
                    </div>
                ) : (
                    <div className="container-list-view-departamento">
                        <HeaderListViewDepartamento />
                        {!filtrou ? departamentos.map((departamento, index) => {
                            return (
                                <Departamento onEdit={handleEditarDepartamento} key={index} departamento={departamento} />
                            )
                        }) : departamentosFiltrados.map((departamento, index) => {
                            return (
                                <Departamento onEdit={handleEditarDepartamento} key={index} departamento={departamento} />
                            )
                        })}
                    </div>
                )}
        </div>
    )
}

const NenhumEncontrada = () => {
    return (
        <div className="nenhum-departamento-encontrada-container">
            <IoIosWarning 
                className="icone-departamento-na-encontrada"
            />
            <h2 className="h2-nenhum-resultado-encontrado">NENHUM RESULTADO ENCONTRADO</h2>
            <p className="referencia-cadastro-nova-departamento">Tente colocar outros filtros, ou <a href="/cadastrar-departamento">cadastre um novo departamento</a>.</p>
        </div>
    )
}

const HeaderListViewDepartamento = () => {
    return (
        <div className="header-list-view-departamento">
            <span className="header-acao-departamento">AÇÃO</span>
            <span className="header-nome-departamento">NOME DO DEPARTAMENTO</span>
            <span className="header-sigla-departamento">SIGLA</span>
            <span className="header-situacao-departamento">SITUAÇÃO</span>
            <span className="header-usuarios-vinculados-departamento">USUÁRIOS VINCULADOS</span>
        </div>
    )
}

const Departamento = ({departamento, onEdit}) => {

    return (
        <div className="departamento-list-view-departamento">
            <span className="acao-departamento">
                <IconeEditar
                    onClick={onEdit.bind(this, departamento.idDepartamento)}
                />
            </span>
            <span className="nome-departamento">{departamento.nomeDepartamento}</span>
            <span className="sigla-departamento">{departamento.sigla}</span>
            <span className="situacao-departamento">
                <SituacaoCadastro
                    situacao={departamento.situacaoCadastro}
                />
            </span>
            <span className="usuarios-vinculados-departamento">{0}</span>
        </div>
    )
}

const IconeEditar = ({
    onClick
}) => {
    return (
        <HiPencil
            className="icone-editar-departamento"
            onClick={onClick}
        />
    )
}

export default ListViewDepartamento;