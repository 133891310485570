import './SituacaoCadastro.css';

function SituacaoCadastro({
    situacao
}) {
  return (
    <p className={`ativo-inativo-texto sit-${situacao.idSituacaoCadastro}`}>{situacao.descricao}</p>
  )
}

export default SituacaoCadastro;