import './EditarChamadoContainer.css';
import ConfigEditarChamado from '../ConfigEditarChamado/ConfigEditarChamado';
import FormEditarChamado from '../FormEditarChamado/FormEditarChamado';
import { useContext, useEffect, useState } from 'react';
import { getChamadoById } from '../../services/ticketService';
import { EditTicketContext } from '../../context/EditTicketContext';
import ComentarioChamado from '../ComentarioChamado/ComentarioChamado';
import ListaComentarios from '../ListaComentarios/ListaComentarios';
import Loading from '../Loading/Loading';

function EditarChamadoContainer({ idChamado }) {

    const [ loading, setLoading ] = useState(true);

    const { setTicketData, ticketData, setDepartamentoEscolhido, verificaSePodeEditarEsseChamado } = useContext(EditTicketContext);

    useEffect(() => {
        const start = Date.now();
        getChamadoById(idChamado).then((chamado) => {
            // Forçar um tempo mínimo de exibição de loading (ex: 500ms)
            const elapsed = Date.now() - start;
            const delay = Math.max(500 - elapsed, 0);
    
            setTimeout(() => {
                setTicketData(chamado);
                setDepartamentoEscolhido(chamado.departamentoEmpresa);
                verificaSePodeEditarEsseChamado(chamado.usuarioResponsavelAtual);
                setLoading(false);
            }, delay);
        });
    }, []);

    return (
        <div className="container-editar-chamado">
            {loading && <Loading />}
            <div className="editar-chamado-esquerda">
                <FormEditarChamado />
                <ComentarioChamado chamado={ticketData} />
                <ListaComentarios comentarios={ticketData.comentarios} />
            </div>
            
            <ConfigEditarChamado />
        </div>
    )
}

export default EditarChamadoContainer