export const localDateTimeArrToStringFormatado = (dataArr) => {
    let dataString = '';
    
    let ano = dataArr[0];
    let mes = dataArr[1];
    if(mes < 10) mes = `0${mes}`;
    let dia = dataArr[2];
    if(dia < 10) dia = `0${dia}`;

    let hora = dataArr[3];
    if(hora < 10) hora = `0${hora}`;
    let minuto = dataArr[4];
    if(minuto < 10) minuto = `0${minuto}`;

    dataString = `${dia}/${mes}/${ano} às ${hora}:${minuto}`;

    return dataString;
}