import api from "./api";

const endpoint = '/imagem_';

export const saveAllImagem = async (imagens) => {
    try {
        // Enviando as imagens
        const response = await api.post(endpoint + "/all", imagens);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}