import axios from 'axios';

const API_KEY = process.env.REACT_APP_CHAVE_API_IMAGEM;

const saveImages = async (image) => {

    const formData = new FormData();
    formData.append('image', image);
    let url = "";
    let titulo = "";

    try {
        const response = await axios.post('https://api.imgbb.com/1/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            params: {
            key: API_KEY,
            },
        });

        url = response.data.data.url;
        titulo = response.data.data.title;

        const imagem = {
            tituloImagem: titulo,
            enderecoImagem: url 
        }

        return imagem;
    } catch (error) {
        console.error('Erro ao fazer upload da imagem:', error);
    }
}

export { saveImages };
