import './ComentarioChamado.css'
import BotaoPadrao from '../BotaoPadrao/BotaoPadrao';
import { useAuth } from '../../services/AuthContext';
import { IoClose } from "react-icons/io5";
import { validaCampoVazio } from '../../validations/basicsValidations';
import { createComentario } from '../../services/comentarioService';
import { useContext, useState } from 'react';
import { EditTicketContext } from '../../context/EditTicketContext';
import NotifyUtils from '../../utils/NotifyUtils';
import { IoIosAttach } from 'react-icons/io';
import { LuImagePlus } from 'react-icons/lu';
import { saveAllImagemComentario } from '../../services/imagemComentarioService';
import { saveAllImagem } from '../../services/imagemService';

function ComentarioChamado({ chamado }) {

    const { user } = useAuth();
    const { adicionaComentario, handleAddFile, getTotalArquivos, imagensParaSalvar, anexosParaSalvar, handleImageUpload, limpaImagens } = useContext(EditTicketContext);

    const [ comentario, setComentario ] = useState('');
    const [ botaoDesabilitado, setBotaoDesabilitado ] = useState(false);

    const comentar = async () => {
        setBotaoDesabilitado(true);
        let comentarioValido = validaCampoVazio(comentario);

        if(!comentarioValido) {
            NotifyUtils.erro('O campo de comentário não pode estar vazio!');
            setBotaoDesabilitado(false);
            return;
        }

        let temImagem = imagensParaSalvar.length > 0;
        let temAnexo = anexosParaSalvar.length > 0;

        let imagemOk = false;
        let anexoOk = true;
        let comentarioOk = false;

        // Monta o objeto de comentário
        const comentarioParaSalvar = {
            chamado: chamado,
            descricao: comentario,
            usuarioComentario: user
        }

        let comentarioCriado = await createComentario(comentarioParaSalvar);

        if(comentarioCriado) {
            comentarioOk = true;
        }

        if(temImagem) {
            const imagensUpadas = await handleImageUpload();

            if(imagensUpadas) {
                const imagensSalvas = await saveAllImagem(imagensUpadas);

                if(imagensSalvas) {
                    const imagensComentariosParaSalvar = imagensSalvas.map(imagemSalva => ({
                        imagem: imagemSalva,
                        comentario: comentarioCriado,
                    }));

                    const imagensChamadoSalvas = await saveAllImagemComentario(imagensComentariosParaSalvar);

                    if(imagensChamadoSalvas) {
                        comentarioCriado.imagens = [];
                        for(let i = 0; i < imagensChamadoSalvas.length; i++) {
                            comentarioCriado.imagens.push(imagensChamadoSalvas[i].imagem);
                        }
                        imagemOk = true;
                    }
                } else {
                    imagemOk = false;
                }
            } else {
                imagemOk = false;
            }
        } else {
            comentarioCriado.imagens = [];
            imagemOk = true;
        }

        limpaImagens();

        // Adiciona esse comentário na lista de comentários do chamado do front
        adicionaComentario(comentarioCriado);

        if(comentarioCriado) {
            NotifyUtils.sucesso('Comentário adicionado com sucesso!');
            setComentario('');
        }

        setBotaoDesabilitado(false);
    }

    const handleChange = (novoValor) => {
        setComentario(novoValor);
    }

    return (
        <div className="comentar-no-chamado-container">

            <div className="conteudo-comentario-chamado">
            <textarea 
                value={comentario} 
                onChange={(e) => handleChange(e.target.value)} 
                className='conteudo-comentario' 
                id="descricao-comentario" 
                name="descricao-comentario" 
                rows="7" 
                cols="50" 
                placeholder='Escreva aqui seu comentário e/ou observação pertinente a esse chamado.'>
            </textarea>

            {/* Por enquanto não vou mostrar a foto na tela */}
            {/* {imagensParaSalvar.length > 0 
                && <ImagensSelecionadas
                        imagens={[...imagensParaSalvar]}
                        onRemove={(index) => handleFileRemove(index, 'imagens')}
                    />} */}
            </div>

            <div className="anexos-e-adicionais-container">
                <div className="container-selecionar-arquivos">
                    {/* <div className="container-input-chamado">
                        <label className="input-file-label" htmlFor="anexo-chamado">
                            <IoIosAttach />
                        </label>
                        <input onChange={(e) => {handleAddFile(e, 'attachment')}} type="file" id="anexo-chamado" name="anexo-chamado" style={{ display: 'none' }} />
                    </div> */}

                    <div className="container-input-chamado">
                        <label className="input-file-label" htmlFor="imagem-chamado">
                            <LuImagePlus />
                        </label>
                        <input onChange={(e) => {handleAddFile(e, 'imagens')}} type="file" id="imagem-chamado" name="imagem-chamado" style={{ display: 'none' }} />
                    </div>
                </div>

                <div className="informacoes-anexos-adicionados">
                    <p className="quantidade-arquivos-adicionados">{getTotalArquivos()}</p>
                    <p className="textos-arquivos-adicionados">ARQUIVOS ADICIONADOS</p>

                    <BotaoPadrao
                        acaoDeClicar={() => comentar()}
                        texto={'Enviar Nota'}
                        confirmar={true}
                        classCustom={'botao-comentar-chamado'}
                        disabled={botaoDesabilitado}
                    />
                </div>
            </div>
                
            
        </div>
    )
}

// const ImagensSelecionadas = ({
//     imagens,
//     onRemove
// }) => {

//     return (
//         <div className="imagens-adicionadas-pelo-usuario-coment">
//             {imagens.map((imagem, index) => (
//                 <div key={index} className="container-imagem-selecionada">
//                     <IoClose onClick={() => onRemove(index)} />
//                     <img className="imagem-selecionada-pelo-usuario-chamado" src={imagem.url} alt="Arquivo Selecionado" />
//                 </div>
//             ))}
//         </div>
//     )
// }

export default ComentarioChamado;