import { IoIosWarning } from 'react-icons/io';
import './ListViewUsuario.css';
import { HiPencil } from 'react-icons/hi';
import SituacaoCadastro from '../SituacaoCadastro/SituacaoCadastro';
import { useLocation } from 'react-router-dom';
import { getUsuarios } from '../../services/usuarioService';
import { useContext, useEffect, useState } from 'react';
import { UsuarioFilterContext } from '../../context/UsuarioFilterContext';
import Loading from '../Loading/Loading';

function ListViewUsuario() {

    const [ loading, setLoading ] = useState(true);

    const { usuarioFilter, filtrar, setFiltrar, verificaFiltroVazio } = useContext(UsuarioFilterContext);

    const [ usuarios, setUsuarios ] = useState([]);
    const [ usuariosFiltrados, setUsuariosFiltrados ] = useState([]);
    const [ filtrou, setFiltrou ] = useState(false);

    useEffect(() => {
        if(filtrar) {
            aplicarFiltro();
            setFiltrar(false);
        }
    }, [filtrar]);

    const handleEditarUsuario = (idUsuario) => {
        // Função que direciona para a página de edição do usuário
        window.location.href = `/editar-usuario/${idUsuario}`;
    }

    useEffect(() => {
        async function fetchUsuarios() {
            const start = Date.now();
            const usuariosFetched = await getUsuarios();

            // Forçar um tempo mínimo de exibição de loading (ex: 500ms)
            const elapsed = Date.now() - start;
            const delay = Math.max(500 - elapsed, 0);
    
            setTimeout(() => {
                setUsuarios(usuariosFetched);
                setLoading(false);
            }, delay);
        }

        fetchUsuarios();
    }, []);

    const aplicarFiltro = () => {
        let tempUsuarios = [];

        if (usuarioFilter === undefined) return;

        if (verificaFiltroVazio()) {
            setFiltrou(false);
            setFiltrar(false);
            return;
        }

        usuarios.forEach((usuario) => {
            // Define tudo como lowerCase para facilitar a comparação
            const nomeComparacao = usuario.nome.toLowerCase();

            const nomeInclui = (nomeComparacao.includes(usuarioFilter.nome) && usuarioFilter.nome !== '') || usuarioFilter.nome === '';
            const emailInclui = (usuario.email.includes(usuarioFilter.email) && usuarioFilter.email !== '') || usuarioFilter.email === '';
            const cpfInclui = (usuario.cpf.includes(usuarioFilter.cpf) && usuarioFilter.cpf !== '') || usuarioFilter.cpf === '';
            const situacaoCadastroIgual = (usuario.situacaoCadastro.idSituacaoCadastro === usuarioFilter.situacaoCadastro && usuarioFilter.situacaoCadastro !== '') || usuarioFilter.situacaoCadastro === '';

            // Verifica se o filtro está presente em algum dos campos
            if (nomeInclui
                && emailInclui
                && cpfInclui
                && situacaoCadastroIgual) {
                tempUsuarios.push(usuario);
            }
        });

        setFiltrou(true);
        setUsuariosFiltrados(tempUsuarios);
    };

    return (
        <div>
            {loading && <Loading />}
            <div className="quantitativo-resultados-encontrados-list-view-usuarios">
                <span className="quantidade-usuarios-encontradas">{usuarios.length}</span>
                <p className="texto-resultado-usuarios-encontradas">Resultados Encontrados</p>
            </div>

            {usuarios.length == 0 
                ? (
                    <div className="container-list-view-usuario-nao-encontrado">
                        <NenhumEncontrado />
                    </div>
                ) : (
                    <div className="container-list-view-empresa">
                        <HeaderListViewUsuario />
                        {!filtrou ? usuarios.map((usuario, index) => {
                            return (
                                <Usuario onEdit={handleEditarUsuario} key={index} usuario={usuario} />
                            )
                        }) : usuariosFiltrados.map((usuario, index) => {
                            return (
                                <Usuario onEdit={handleEditarUsuario} key={index} usuario={usuario} />
                            )
                        })}
                    </div>
                )}
        </div>
    )
}

const NenhumEncontrado = () => {
    return (
        <div className="nenhuma-usuario-encontrada-container">
            <IoIosWarning 
                className="icone-usuario-na-encontrada"
            />
            <h2 className="h2-nenhum-resultado-encontrado">NENHUM RESULTADO ENCONTRADO</h2>
            <p className="referencia-cadastro-nova-empresa">Tente colocar outros filtros, ou <a href="/cadastrar-usuário">cadastre um novo usuário</a>.</p>
        </div>
    )
}

const HeaderListViewUsuario = () => {
    return (
        <div className="header-list-view-usuario">
            <span className="header-acao">AÇÃO</span>
            <span className="header-nome-usuario">NOME DO USUÁRIO</span>
            <span className="header-tipo-usuario">TIPO DO USUÁRIO</span>
            <span className="header-situacao-usuario">SITUAÇÃO</span>
            <span className="header-cpf-usuario">CPF</span>
            <span className="header-email-usuario">EMAIL</span>
        </div>
    )
}

const Usuario = ({usuario, onEdit}) => {

    const cpfNaoCadastrado = "Não cadastrado";

    const formataCPF = (cpf) => {
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }
    
    return (
        <div className="usuario-list-view-usuario">
            <span className="acao-usuario">
                <IconeEditar
                    onClick={onEdit.bind(this, usuario.idUsuario)}
                />
            </span>
            <span className="nome-usuario">{usuario.nome}</span>
            <span className="tipo-do-usuario">{usuario.perfilUsuario.descricao}</span>
            <span className="situacao-cadastro-usuario">
                <SituacaoCadastro
                    situacao={usuario.situacaoCadastro}
                />
            </span>
            <span className="cpf-usuario">
                {usuario.cpf !== "" ? formataCPF(usuario.cpf) : cpfNaoCadastrado}
            </span>
            <span className="email-usuario">{usuario.email}</span>
        </div>
    )
}

const IconeEditar = ({
    onClick
}) => {
    return (
        <HiPencil
            className="icone-editar-usuario"
            onClick={onClick}
        />
    )
}

export default ListViewUsuario