import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createChamado } from '../services/ticketService'; 
import { saveImages } from '../services/provisorioImageService'; 
import { saveAllImagem } from '../services/imagemService'; 
import { validaCampoVazio } from '../validations/basicsValidations';
import { useAuth } from '../services/AuthContext';
import { saveAllImagemChamado } from '../services/imagemChamadoService';
import { montaResposta } from '../utils/ResponsesUtils';
import { getDepartamentoEmpresa } from '../services/departamentoEmpresaService';
import { getUsuarioByDepartamentoEmpresaList, getUsuarios } from '../services/usuarioService';

export const TicketContext = createContext();

export const TicketProvider = ({ children }) => {

  const { user } = useAuth();
  const navigate = useNavigate();

  const [ loading, setLoading ] = useState(true);

  const [ticketData, setTicketData] = useState({
    usuarioResponsavel: '',
    usuarioResponsavelAtual: user,
    departamentoEmpresa: '',
    status: '',
    descricao: '',
    sistema: {
      idSistema: 1,
      descricao: "padrao"
    },
    imagens: [],
    anexos: [],
  });

  const [usuarioEscolhido, setUsuarioEscolhido] = useState(null);
  const [departamentoEscolhido, setDepartamentoEscolhido] = useState(null);
  const [departamentosDisponiveis, setDepartamentosDisponiveis] = useState([]);
  const [usuariosDisponiveis, setUsuariosDisponiveis] = useState([]);
  const [cadastrandoChamado, setCadastrandoChamado] = useState(false);

  useEffect(() => {
    if (usuarioEscolhido === null) {
      getDepartamentoEmpresa().then((departamentosEncontrados) => {
        setDepartamentosDisponiveis(departamentosEncontrados);
      }).catch((error) => {
        console.error(error);
      });
    }
  }, [usuarioEscolhido]);

  useEffect(() => {
    setLoading(true);

    if (departamentoEscolhido === null) {
      getUsuarios().then((usuarios) => {
        setUsuariosDisponiveis(usuarios);
      }).catch((error) => {
        console.error(error);
      });
    } else {
      getUsuarioByDepartamentoEmpresaList(departamentoEscolhido).then((usuariosEncontrados) => {
        setUsuariosDisponiveis(usuariosEncontrados);
      }).catch((error) => {
        console.error(error);
      });
    }
  
    setLoading(false);
  }, [departamentoEscolhido]);

  const handleChange = (field, value) => {
    setTicketData(prevState => ({
      ...prevState,
      [field]: value,
    }));

    if (field === 'departamentoEmpresa') {
      setDepartamentoEscolhido(value);
    }
  };

  const handleSubmit = async () => {
    const responsavel = { idUsuario: user.idUsuario };
    const newTicketData = { ...ticketData, usuarioResponsavel: responsavel };
    
    const assuntoValido = validaCampoVazio(newTicketData.assunto);
    const descricaoValida = validaCampoVazio(newTicketData.descricao);
    const estadoValido = validaCampoVazio(newTicketData.status);
    const departamentoValido = validaCampoVazio(newTicketData.departamentoEmpresa);
    const usuarioResponsavelValido = validaCampoVazio(newTicketData.usuarioResponsavel);
    const usuarioResponsavelAtualValido = validaCampoVazio(newTicketData.usuarioResponsavelAtual);

    if (
      assuntoValido &&
      descricaoValida &&
      estadoValido &&
      departamentoValido &&
      usuarioResponsavelValido &&
      usuarioResponsavelAtualValido
    ) {
      setCadastrandoChamado(true);
      const chamadoSalvo = await createChamado(newTicketData);

      if (chamadoSalvo) {
        if (newTicketData.imagens.length > 0) {
          try {
            const imagensUpadas = await handleImageUpload();
            newTicketData.imagens = imagensUpadas;
          } catch (error) {
            console.error('Erro ao carregar imagens');
            setCadastrandoChamado(false);
            return montaResposta(false, 'Erro ao carregar imagens do chamado', null, null);
          }
        }

        if (newTicketData.imagens.length > 0) {
          const imagensSalvas = await saveAllImagem(newTicketData.imagens);

          if (imagensSalvas) {
            const imagensChamadosParaSalvar = imagensSalvas.map(imagemSalva => ({
              imagem: imagemSalva,
              chamado: chamadoSalvo,
            }));

            const imagensChamadoSalvas = await saveAllImagemChamado(imagensChamadosParaSalvar);

            if (imagensChamadoSalvas) {
              setCadastrandoChamado(false);
              return montaResposta(true, 'Chamado cadastrado com sucesso!', null, null);
            }
          }

          setCadastrandoChamado(false);
          return montaResposta(false, 'Erro ao salvar imagens do chamado', null, null);
        } else {
          setCadastrandoChamado(false);
          return montaResposta(true, 'Chamado cadastrado com sucesso!', null, null);
        }
      }
    } else {
      return montaResposta(
        undefined,
        'Preencha corretamente os campos obrigatórios.',
        null,
        {
          assuntoValido,
          descricaoValida,
          estadoValido,
          departamentoValido,
          usuarioResponsavelValido,
          usuarioResponsavelAtualValido,
        }
      );
    }
  };

  const handleCancelling = () => {
    handleClear();
    voltarTelaInicial();
  };

  const handleClear = () => {
    setTicketData({
      usuarioResponsavel: '',
      usuarioResponsavelAtual: user,
      departamentoEmpresa: '',
      status: '',
      sistema: {
        idSistema: 1,
        descricao: "padrao"
      },
      descricao: '',
      imagens: [],
      anexos: [],
    });
  };

  const handleFileChange = (e, type) => {
    const file = e.target.files[0];
    if (!file) return;

    let tipo = '';
    switch (type) {
      case 'attachment':
        tipo = 'anexos';
        break;
      case 'imagens':
        tipo = 'imagens';
        break;
      default:
        return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setTicketData(prevState => ({
        ...prevState,
        [tipo]: [
          ...prevState[tipo],
          { file, url: reader.result }
        ]
      }));
    };
    reader.readAsDataURL(file);
  };

  const handleFileRemove = (index, type) => {
    setTicketData(prevState => {
      const updatedFiles = [...prevState[type]];
      updatedFiles.splice(index, 1);
      return {
        ...prevState,
        [type]: updatedFiles
      };
    });
  };

  const handleImageUpload = async () => {
    try {
      const uploadPromises = ticketData.imagens.map(image => saveImages(image.file));
      const urls = await Promise.all(uploadPromises);
      return urls;
    } catch (error) {
      console.error('Erro ao fazer upload das imagens:', error);
      throw error;
    }
  };

  const voltarTelaInicial = () => {
    navigate('/');
  };

  return (
    <TicketContext.Provider
      value={{
        ticketData,
        handleFileRemove,
        handleChange,
        handleSubmit,
        handleCancelling,
        departamentosDisponiveis,
        usuariosDisponiveis,
        handleFileChange,
        cadastrandoChamado,
        loading,
        setLoading
      }}
    >
      {children}
    </TicketContext.Provider>
  );
};
