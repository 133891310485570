import './PainelExpansivelChamado.css';
import { useState } from 'react';
import { MdArrowDropDown } from "react-icons/md";
import { IoMdArrowDropup } from "react-icons/io";
import ChamadoList from '../ChamadoList/ChamadoList';
import { ExpansionPanel } from 'react-md';

function PainelExpansivelChamado({ tipoListaChamado, listaDeChamados, titulo, semChamados, filtrar }) {
    const chamados = listaDeChamados;
    let tituloExpansivel = titulo;

    const [expanded, setExpanded] = useState(false);

    const handleToggle = () => {
        setExpanded(!expanded);
    }
    
    return (
        !semChamados ? (
        <ExpansionPanel
            expanded={expanded}
            onExpandClick={handleToggle}
            header={`${tituloExpansivel} (${chamados.length})`}
            className={`expansion-panel-header`}
            headerClassName={`expansion-panel-header tipo${tipoListaChamado} ${expanded ? "" : "naoEstaExpandido"}`}
            contentClassName={`expansion-panel-content ${expanded ? 'expanded' : ""}`}
            expanderIcon={expanded ? <IoMdArrowDropup className="icone-expansion-paineis"/> : <MdArrowDropDown className={`icone-expansion-paineis ${expanded}`}/>}>
            <ul>
                {chamados.map((chamado, index) => (
                    <ChamadoList
                        key={index}
                        chamado={chamado}
                        idChamado={chamado.idChamado}
                        tipoListaChamado={tipoListaChamado}
                        filhoUnico={chamados.length === 1}
                    />
                ))}
            </ul>
        </ExpansionPanel>) : (
                <ExpansionPanel
                    expanded={expanded}
                    header={`${tituloExpansivel} - Nenhum chamado encontrado`}
                    className={`expansion-panel-header`}
                    headerClassName={`expansion-panel-header sem-chamados tipo${tipoListaChamado} ${expanded ? "" : "naoEstaExpandido"}`}
                    contentClassName={`expansion-panel-content ${expanded ? 'expanded' : ""}`}
                    expanderIcon={<MdArrowDropDown className={`icone-expansion-paineis ${"sem-chamados"}`}/>}>
                </ExpansionPanel>
        )
    )
}

export default PainelExpansivelChamado;

