import { createContext, useState } from "react";

export const DepartamentoFilterContext = createContext();

export const DepartamentoFilterProvider = ({ children }) => {

    const [filtrar, setFiltrar] = useState(false);
    const [departamentoFilter, setDepartamentoFilter] = useState({
        nomeDepartamento: '',
        situacaoCadastro: ''
    });

    const verificaFiltroVazio = () => {
        // Verifica se algum dos campos está vazio
        if(departamentoFilter.nomeDepartamento === '' 
            && departamentoFilter.situacaoCadastro === '') {
                return true;
        }
    
        return false;
    }

    const handleChange = (inputPara, value) => {
        let novoDepartamentoFilter = {
            nomeDepartamento: departamentoFilter.nomeDepartamento,
            situacaoCadastro: departamentoFilter.situacaoCadastro,
        }

        novoDepartamentoFilter[inputPara] = retornaValorFiltro(inputPara, value);
        setDepartamentoFilter(novoDepartamentoFilter);
    };

    const handleClear = () => {
        setDepartamentoFilter({
            nomeDepartamento: '',
            situacaoCadastro: ''
        });
    }

    const handleSearch = () => {
        setFiltrar(true);
    };

    return (
        <DepartamentoFilterContext.Provider value={{ 
            departamentoFilter, 
            handleChange, 
            filtrar, 
            setFiltrar, 
            handleSearch,
            verificaFiltroVazio,
            handleClear
        }}>
            {children}
        </DepartamentoFilterContext.Provider>
    );
};

const retornaValorFiltro = (inputPara, filtro) => {

    let retorno = "";

    switch(inputPara) {
        case "nomeDepartamento":
            retorno = filtro;
            break;
        case "situacaoCadastro":
            retorno = filtro.idSituacaoCadastro;
            break;
        default:
            retorno = "erro";
            break;
    };

    return retorno;
};