import api from "./api";

const endpoint = "/departamento_";

export const createDepartamento = async (departamento) => {
    try {
        const response = await api.post(endpoint, departamento, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const getDepartamentos = async () => {
    try {
        const response = await api.get(endpoint);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}

export const getDepartamentoById = async (idDepartamento) => {
    try {
        const response = await api.get(endpoint + `/${idDepartamento}`);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}

export const updateDepartamento = async (departamento) => {
    try {
        const response = await api.put(endpoint + `/${departamento.idDepartamento}`, departamento, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}