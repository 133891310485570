import './FormAbrirChamado.css'
import { IoDocumentText, IoClose } from "react-icons/io5";
import { IoIosAttach } from "react-icons/io";
import { LuImagePlus } from "react-icons/lu";
import CampoObrigatorio from '../CampoObrigatorio/CampoObrigatorio';
import { TicketContext } from '../../context/TicketContext';
import { useContext } from 'react';
import HeaderContainers from '../HeaderContainers/HeaderContainers';
import Loading from '../Loading/Loading';

function FormAbrirChamado() {

    const { ticketData, handleChange, handleFileChange, handleFileRemove, loading } = useContext(TicketContext);

    const mudarAssunto = "assunto";
    const mudarDescricao = "descricao";

    return (
        <div className="criar-novo-ticket-container">
            {loading && <Loading />}
            <HeaderContainers texto="abrir chamado" />

            <div className="formulario-abrir-novo-chamado">
                <form>
                    <div className="container-input-chamado">
                        <label htmlFor="assunto-chamado">
                            ASSUNTO <CampoObrigatorio />
                        </label>
                        <input  type="text" id='assunto-chamado' name="assunto-chamado" onChange={(e) => {handleChange(mudarAssunto, e.target.value)}} placeholder='Escreva um titulo para este chamado.'/>   
                    </div>

                    <div className="container-input-chamado">
                        <label htmlFor="descricao-chamado">
                            <IoDocumentText className="icon-azul" />
                            DESCRIÇÃO DO CHAMADO <CampoObrigatorio />
                        </label>

                        {ticketData.imagens.length > 0 
                            && <ImagensSelecionadas
                                    imagens={[ticketData.imagens]}
                                    onRemove={(index) => handleFileRemove(index, 'imagens')}
                                />}

                        <textarea onChange={(e) => {handleChange(mudarDescricao, e.target.value)}} id="descricao-chamado" name="descricao-chamado" rows="7" cols="50" placeholder='Descreva a situação do chamado.'>
                        </textarea>
                    </div>

                    <div className="anexos-e-adicionais-container">
                        <div className="container-selecionar-arquivos">
                            {/* <div className="container-input-chamado">
                                <label className="input-file-label" htmlFor="anexo-chamado">
                                    <IoIosAttach />
                                </label>
                                <input onChange={(e) => {handleFileChange(e, 'attachment')}} type="file" id="anexo-chamado" name="anexo-chamado" style={{ display: 'none' }} />
                            </div> */}

                            <div className="container-input-chamado">
                                <label className="input-file-label" htmlFor="imagem-chamado">
                                    <LuImagePlus />
                                </label>
                                <input onChange={(e) => {handleFileChange(e, 'imagens')}} type="file" id="imagem-chamado" name="imagem-chamado" style={{ display: 'none' }} />
                            </div>
                        </div>

                        <div className="informacoes-anexos-adicionados">
                            <p className="quantidade-arquivos-adicionados">{ticketData.imagens.length}</p>
                            <p className="textos-arquivos-adicionados">ARQUIVOS ADICIONADOS</p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

const ImagensSelecionadas = ({
    imagens,
    onRemove
}) => {

    return (
        <div className="imagens-adicionadas-pelo-usuario">
            {imagens[0].map((imagem, index) => (
                <div key={index} className="container-imagem-selecionada">
                    <IoClose onClick={() => onRemove(index)} />
                    <img className="imagem-selecionada-pelo-usuario-chamado" src={imagem.url} alt="Arquivo Selecionado" />
                </div>
            ))}
        </div>
    )
}

export default FormAbrirChamado