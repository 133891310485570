import FiltroEmpresaContainer from '../FiltroEmpresaContainer/FiltroEmpresaContainer';
import ListViewEmpresa from '../ListViewEmpresa/ListViewEmpresa';
import './EmpresasContainer.css';
import { getEmpresas } from '../../services/empresaService';
import { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { EmpresaFilterContext } from '../../context/EmpresaFilterContext';
import { useAuth } from '../../services/AuthContext';
import Loading from '../Loading/Loading';

function EmpresasContainer() {

  const { user } = useAuth();

  const [ loading, setLoading ] = useState(true);

  const { empresaFilter, filtrar, setFiltrar, verificaFiltroVazio } = useContext(EmpresaFilterContext);

  const [ filtrou, setFiltrou ] = useState(false);
  const [ empresas, setEmpresas ] = useState([]);
  const [ empresasFiltradas, setEmpresasFiltradas ] = useState([]);

  useEffect(() => {
      async function fetchEmpresas() {
          const start = Date.now();
          const empresasFetched = await getEmpresas();
          
          // Forçar um tempo mínimo de exibição de loading (ex: 500ms)
          const elapsed = Date.now() - start;
          const delay = Math.max(500 - elapsed, 0);
  
          setTimeout(() => {
            setEmpresas(empresasFetched);
            setLoading(false);
          }, delay);
      }

      fetchEmpresas();
  }, []);

  const podeCadastrar = user.podeCadastrarEmpresa;

  useEffect(() => {
    if(filtrar) {
      aplicarFiltro();
      setFiltrar(false);
    }

  }, [filtrar]);

  const aplicarFiltro = () => {
    let tempEmpresas = [];

    if(empresaFilter === undefined) return;

    if(verificaFiltroVazio()) {
      setFiltrou(false);
      setFiltrar(false);
      return;
    }

    empresas.forEach((empresa) => {
      // Define tudo como lowerCase para facilitar a comparação
      const razaoComparacao = empresa.razaoSocial.toLowerCase();
      const siglaComparacao = empresa.sigla.toLowerCase();
      const numDocumentoComparacao = empresa.numDocumento.toLowerCase();

      const razaoSocialInclui = (razaoComparacao.includes(empresaFilter.razaoSocial.toLowerCase()) && empresaFilter.razaoSocial !== '') || empresaFilter.razaoSocial === '';
      const siglaInclui = (siglaComparacao.includes(empresaFilter.sigla.toLowerCase()) && empresaFilter.sigla !== '') || empresaFilter.sigla === '';
      const numDocumentoInclui = (numDocumentoComparacao.includes(empresaFilter.numDocumento) && empresaFilter.numDocumento !== '') || empresaFilter.numDocumento === '';
      const sitCadastroIgual = (empresa.situacaoCadastro.idSituacaoCadastro === empresaFilter.situacaoCadastro && empresaFilter.situacaoCadastro !== '') || empresaFilter.situacaoCadastro === '';
      const tipoDocumentoIgual = (empresa.tipoDocumento.idTipoDocumento === empresaFilter.tipoDocumento && empresaFilter.tipoDocumento !== '') || empresaFilter.tipoDocumento === '';

      // Verifica se o filtro está presente em algum dos campos
      if(razaoSocialInclui 
        && siglaInclui 
        && numDocumentoInclui
        && sitCadastroIgual
        && tipoDocumentoIgual) {
          tempEmpresas.push(empresa);
      }
    });

    setFiltrou(true);
    setEmpresasFiltradas(tempEmpresas);
  };

  return (
    <div className="container">
        {loading && <Loading />}
        <FiltroEmpresaContainer podeCadastrar={podeCadastrar} />
        <ListViewEmpresa empresas={filtrou ? empresasFiltradas : empresas} />
    </div>
  )
}

export default EmpresasContainer;