import './TopContainer.css';
import { IoIosArrowDown } from "react-icons/io";
import { FaSearch, FaPlus } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { TicketFilterContext } from '../../context/TicketFilterContext';
import NotifyUtils from '../../utils/NotifyUtils';

function TopContainer() {
    const { ticketFilter, handleChange, handleSearch } = useContext(TicketFilterContext);

    const naoDisponivel = (event) => {
        event.preventDefault();
        NotifyUtils.custom("Função em desenvolvimento...");
      }

    return (
        <div className="top-container">
            <div className="header-top-container">
                <div className="textos-header-top-container">
                    <h3>SELECIONE OS FILTROS</h3>
                    <p onClick={(e) => {naoDisponivel(e)}} className="botao-filtros-avancados-top-container">Clique para ver filtros avançados <IoIosArrowDown /></p>
                </div>
                <hr className="divisor-top-container" />
            </div>
            <div className="filtro-top-container">
                <label htmlFor="pesquisa-contem" className="pesquisa-contem-label">
                    <FaSearch className="icone-pesquisa-top-container" />CONTÉM O TEXTO</label>
                <div className="div-abaixo-top-container">
                    <div className="pesquisa-simples-top-container">
                        <input
                            type="text" className="pesquisa-contem" placeholder="Pesquise por título, descrição, número de chamado ou tags (ex: #0098, @empresa-x)"
                            onChange={(e) => { handleChange(e.target.value) }}
                            value={ticketFilter}
                        />
                    </div>
                    <div className="botoes-top-container">
                        <button className="botao-pesquisar-top-container" onClick={handleSearch}>PESQUISAR <FaSearch /></button>
                        <Link className="link abrir-chamado" to="/abrir-chamado">
                            <button className="botao-novo-chamado-top-container novo-chamado">NOVO CHAMADO <FaPlus /></button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopContainer;
