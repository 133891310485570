import { act, useState } from 'react';
import CampoObrigatorio from '../CampoObrigatorio/CampoObrigatorio';
import './InputSelectPadrao.css';
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";

function InputSelectPadrao({
    className,
    onChange, 
    titulo, 
    obrigatorio, 
    icone,
    name, 
    placeholder, 
    inputPara,
    options,
    extra,
    comErro,
    valorInicial,
    sendAll
}) {

    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(valorInicial !== undefined ? valorInicial : "");

    const toggleDropdown = () => {
        setIsOpen(!isOpen)
    };

    const handleOptionClick = (value) => {
        setSelectedValue(value.descricao);
        if(onChange) {
            let opcaoCompleta;
            if(extra !== undefined) {
                for (let index = 0; index < extra.length; index++) {
                    const opcao = extra[index];
                    
                    if(opcao.departamento.idDepartamento == value.id) {
                        opcaoCompleta = opcao;
                        break;
                    }
                }
            } else {
                opcaoCompleta = value;
            }

            if(sendAll) {
                onChange(inputPara, value);
            } else {
                onChange(inputPara, opcaoCompleta);
            }
        }
        setIsOpen(false);
    };

    return (
        <div className={`container-input-select-padrao ${name} ${className}`}>
            <div className="container-label-input-select-padrao">
                {titulo && (
                    <div className="principais-input-select-padrao">
                        {icone && icone}
                        <label htmlFor={name}> {titulo} </label>

                        {(obrigatorio) && (
                            <div className="container-obrigatorio-input-select-padrao">
                                <CampoObrigatorio />
                            </div>
                        )}
                    </div>
                    )}
            </div>

            <div className="select-padrao-container" 
                onClick={toggleDropdown}>
                    
                <div className={`input-select-padrao-estilo ${comErro ? "erro-invalido" : ""} ${isOpen ? 'open' : ''} ${selectedValue != "" ? "selecionado" : ''}`}>
                    {selectedValue || (placeholder ? placeholder : "Selecione uma opção")}
                    {isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
                </div>

                {isOpen && (
                    <div className="dropdown-options-container">
                        {options.map((option, index) => (
                            <div 
                                key={index}
                                className="dropdown-option"
                                onClick={() => handleOptionClick(option)}
                            >
                                {option.descricao}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default InputSelectPadrao;