import CustomSelectAdvanced from '../CustomSelectAdvanced/CustomSelectAdvanced'
import './ConfigAbrirChamado.css'
import { FaExclamationCircle } from "react-icons/fa";
import { FaOctopusDeploy } from "react-icons/fa";
import { HiUserAdd } from "react-icons/hi";
import { useContext, useEffect, useState } from 'react';
import { TicketContext } from '../../context/TicketContext';
import HeaderContainers from '../HeaderContainers/HeaderContainers';
import { getStatus } from '../../services/statusService';
import BotaoPadrao from '../BotaoPadrao/BotaoPadrao';
import NotifyUtils from '../../utils/NotifyUtils';
import Loading from '../Loading/Loading';

function ConfigAbrirChamado() {

    const { departamentosDisponiveis, usuariosDisponiveis, handleSubmit, handleCancelling, handleChange, loading, setLoading } = useContext(TicketContext);
    const [status, setStatus] = useState(null);

    const textoCarregando = "carregando...";
    const textoConfirmar = "criar chamado";
    const [botaoDesabilitado, setBotaoDesabilitado] = useState(false);
    const [mensagemBotaoConfirmar, setMensagemBotaoConfirmar] = useState(textoConfirmar);

    const handleCreateTicket = async (e) => {
        // Assim que confirmar, desabilita o botão e muda o texto para "carregando..."
        e.preventDefault();
        setBotaoDesabilitado(true);
        setMensagemBotaoConfirmar(textoCarregando)

        NotifyUtils.info("Cadastrando chamado...");

        // Faz o envio de informações para o back caso esteja tudo certo com os campos, se não tiver, retorna os campos inválidos
        const retornoRequest = await handleSubmit();
        const erroCustom = retornoRequest.success === undefined;

        if(retornoRequest === null) {
            NotifyUtils.info("Cadastrando...");
            return;
        }

        if(!retornoRequest.success && !erroCustom) {
            NotifyUtils.erro(retornoRequest.message);
        } else if(retornoRequest.success) {
            NotifyUtils.sucesso(retornoRequest.message);
            NotifyUtils.sucesso("Redirecionando...");

            setTimeout(() => {
                handleCancelling();
                setBotaoDesabilitado(false);
                setMensagemBotaoConfirmar(textoConfirmar);
            }, 3500);
            return;
        } else if(erroCustom) {
            NotifyUtils.erro(retornoRequest.message);
        }

        setBotaoDesabilitado(false);
        setMensagemBotaoConfirmar(textoConfirmar);
    }

    useEffect(() => {
        async function fetchStatus() {
            const start = Date.now();
            const status = await getStatus();
            
            // Forçar um tempo mínimo de exibição de loading (ex: 500ms)
            const elapsed = Date.now() - start;
            const delay = Math.max(500 - elapsed, 0);
    
            setTimeout(() => {
                setStatus(status);
                setLoading(false);
            }, delay);
        }

        fetchStatus();
    }, []);

    let estadosChamados = [];
    let usuariosResponsaveis = [];
    let departamentos = [];

    const [departamentoEmpresa, setDepartamentoEmpresa] = useState(departamentosDisponiveis);
    useEffect(() => {
        setDepartamentoEmpresa(departamentosDisponiveis);
    }, [departamentosDisponiveis]);

    const [usuarios, setUsuarios] = useState(usuariosDisponiveis);
    useEffect(() => {
        setUsuarios(usuariosDisponiveis);
    }, [usuariosDisponiveis]);

    if (status !== null) {
        estadosChamados = status;
    }

    if(usuarios !== null) {
        usuariosResponsaveis = usuarios;
    }

    if(departamentoEmpresa !== null) {
        departamentos = departamentoEmpresa;
    }

    if(loading || status === null || usuarios === null || departamentoEmpresa === null) {
        return <Loading />;
    }

    return (
        <div className="configuracoes-chamado-container">
            <HeaderContainers texto="configurações" />
            <div className="container-selects-configuracao">

                <CustomSelectAdvanced
                    required={true}
                    tipoSelect={"status"}
                    opcoes={estadosChamados}
                    titulo={"ESTADO DO CHAMADO"}
                    icone={<FaExclamationCircle className="icon-azul" />}
                    handleChange={handleChange}
                    extra={estadosChamados}
                    tipoExtra={"status"}
                    opcaoInicial={true}
                    defaultOption={estadosChamados[0].descricao}
                    defaultOptionFull={estadosChamados[0]}
                    podeEscolher={false}
                />

                <CustomSelectAdvanced
                    required={true}
                    tipoSelect={"departamentoEmpresa"}
                    opcoes={separaDepartamentoEmpresa(departamentos)}
                    titulo={"DEPARTAMENTO"}
                    icone={<FaOctopusDeploy className="icon-azul" />}
                    handleChange={handleChange}
                    extra={departamentos}
                    tipoExtra={"departamento"}
                    podeEscolher={true}
                />


                <CustomSelectAdvanced
                    required={true}
                    tipoSelect={"usuarioResponsavelAtual"}
                    opcoes={separaUsuario(usuariosResponsaveis)}
                    titulo={`RESPONSÁVEL`}
                    icone={<HiUserAdd className="icon-azul" />}
                    handleChange={handleChange}
                    tipoExtra={"usuario"}
                    extra={usuariosResponsaveis}
                    podeEscolher={true}
                />

            </div>
            
            <div className="botoes-config-container">
                <BotaoPadrao
                    acaoDeClicar={handleCancelling}
                    texto={"Cancelar"}
                    cancelar={true}
                />
                <BotaoPadrao
                    acaoDeClicar={handleCreateTicket}
                    texto={mensagemBotaoConfirmar}
                    confirmar={true}
                    disabled={botaoDesabilitado}
                />
            </div>
        </div>
    );
}

const separaDepartamentoEmpresa = (listaDepartamentoEmpresa) => {
    let departamentos = [];

    for (let index = 0; index < listaDepartamentoEmpresa.length; index++) {
        const departamentoEmpresa = listaDepartamentoEmpresa[index];
        departamentos.push({ 
            descricao: departamentoEmpresa.departamento.nomeDepartamento,
            identificador: departamentoEmpresa.idDepartamentoEmpresa});
    }

    return departamentos;
}

const separaUsuario = (listaUsuarios) => {
    let usuarios = [];

    for (let index = 0; index < listaUsuarios.length; index++) {
        const usuario = listaUsuarios[index];
        usuarios.push({ 
            descricao: usuario.nome,
            identificador: usuario.idUsuario});
    }

    return usuarios;
}

export default ConfigAbrirChamado;