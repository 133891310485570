import { createContext, useContext, useEffect, useState } from "react";
import api from "./api";
import { useNavigate } from "react-router-dom";
import { limparCache, recuperarCache, salvarCache } from "./cacheHandler";
import NotifyUtils from "../utils/NotifyUtils";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    
    const [user, setUser] = useState(() => {
        // Recupera o usuário do localStorage quando o componente monta
        return recuperarCache('user');
    });
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const login = async (email, senha) => {
        try {
            const response = await api.post('/usuariologin_', { email, senha }, {
                headers: {
                    "Content-Type": "application/json",
                },
            })

            if(response.status !== 200) {
                NotifyUtils.erro('Erro ao realizar login');
            }

            // Se o login for bem sucedido, seta o usuário
            const userLogado = response.data;
            setUser(userLogado);
            salvarCache('user', userLogado);

            setLoading(false);
        } catch (error) {
            NotifyUtils.erro('Login inválido!');
        }
    };

    const logout = () => {
        limparCache('user');
        setUser(null);
        navigate('/login', { replace: true });
    };

    const setPodeCadastrarDepartamento = (pode) => {
        let lastUser = user;
        lastUser.podeCadastrarDepartamento = pode;
        setUser(lastUser);
        salvarCache('user', lastUser);
    };

    const setPodeCadastrarUsuario = (pode) => {
        let lastUser = user;
        lastUser.podeCadastrarUsuario = pode;
        setUser(lastUser);
        salvarCache('user', lastUser);
    };

    useEffect(() => {
        setLoading(false);
    }, []);

    return (
        <AuthContext.Provider value={{ user, login, logout, loading, setPodeCadastrarDepartamento, setPodeCadastrarUsuario }}>
            {children}
        </AuthContext.Provider>
    );
};