import './ListaChamados.css';
import { ExpansionList } from "@react-md/expansion-panel";
import PainelExpansivelChamado from '../PainelExpansivelChamado/PainelExpansivelChamado';
import { getChamadosByUsuario } from '../../services/ticketService';
import { useContext, useEffect, useState } from 'react';
import { getStatus } from '../../services/statusService';
import { useAuth } from '../../services/AuthContext';
import { TicketFilterContext } from '../../context/TicketFilterContext';
import { retornaFiltro, verificaTipoFiltro } from '../../utils/ChamadoUtils';
import Loading from '../Loading/Loading';

function ListaChamados() {

    const { user } = useAuth();
    const [ loading, setLoading ] = useState(true);

    const { ticketFilter, filtrar, setFiltrar } = useContext(TicketFilterContext);

    const [status, setStatus] = useState(null);
    const [listaChamados, setListaChamados] = useState([]);
    const [chamadosFiltrados, setChamadosFiltrados] = useState([]);
    
    
    useEffect(() => {
        const fetchChamados = async () => {
            const start = Date.now();
            const chamadosFetched = await getChamadosByUsuario(user);
            setListaChamados(chamadosFetched);
    
            // Forçar um tempo mínimo de exibição de loading (ex: 500ms)
            const elapsed = Date.now() - start;
            const delay = Math.max(500 - elapsed, 0);
    
            setTimeout(() => {
                setLoading(false);
            }, delay);
        };
    
        fetchChamados();
    }, [user]);

    useEffect(() => {
        async function fetchStatus() {
            try {
                const status = await getStatus();
                setStatus(status);
            } catch (error) {
                console.error(error);
            }
        }

        fetchStatus();
    }, []);

    useEffect(() => {
        if (filtrar) {
            aplicarFiltro();
            setFiltrar(false);
        }
    }, [filtrar]);

    const tipoFiltro = verificaTipoFiltro(ticketFilter);
    const filtro = retornaFiltro(ticketFilter).toLowerCase();

    const aplicarFiltro = () => {
        if (status === null) {
            return;
        }

        let estadosChamados = status.map((estado) => ({
            tipo: estado.idStatus,
            titulo: estado.descricao,
            chamados: []
        }));

        listaChamados.forEach((chamado) => {
            const chamadoComparacao = chamado[tipoFiltro].toString().toLowerCase();
            if (chamadoComparacao.includes(filtro)) {
                const estadoIndex = estadosChamados.findIndex(e => e.tipo === chamado.status.idStatus);
                if (estadoIndex !== -1) {
                    estadosChamados[estadoIndex].chamados.push(chamado);
                }
            }
        });

        setChamadosFiltrados(estadosChamados);
    };

    useEffect(() => {
        aplicarFiltro();
    }, [listaChamados, status]);

    return (
        <div className="expansion-list-container">
            {loading && <Loading />}
            <ExpansionList className="lista-tipo-chamado">
                {chamadosFiltrados.map((tipoChamado, index) => (
                    tipoChamado.chamados.length > 0 ?
                    <PainelExpansivelChamado
                        key={`${tipoChamado.tipo}-${index}-${tipoChamado.titulo}`}
                        listaDeChamados={tipoChamado.chamados}
                        tipoListaChamado={tipoChamado.tipo}
                        titulo={tipoChamado.titulo}
                        semChamados={false}
                        filtrar={filtrar}
                    />
                    : (
                        filtrar ? null : <PainelExpansivelChamado
                            key={`empty-${tipoChamado.tipo}-${index}-${tipoChamado.titulo}`}
                            tipoListaChamado={tipoChamado.tipo}
                            semChamados={true}
                            titulo={tipoChamado.titulo}
                        />
                    )
                ))}
            </ExpansionList>
        </div>
    );
}

export default ListaChamados;
