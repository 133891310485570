import { createContext, useState } from "react";

export const EmpresaFilterContext = createContext();

export const EmpresaFilterProvider = ({ children }) => {
    const [empresaFilter, setEmpresaFilter] = useState({
        razaoSocial: '',
        sigla: '',
        tipoDocumento: '',
        numDocumento: '',
        situacaoCadastro: ''
    });

    const [filtrar, setFiltrar] = useState(false);

    const verificaFiltroVazio = () => {
        // Verifica se algum dos campos está vazio
        if(empresaFilter.razaoSocial === '' 
            && empresaFilter.sigla === ''
            && empresaFilter.numDocumento === ''
            && empresaFilter.situacaoCadastro === ''
            && empresaFilter.tipoDocumento === '') {
                return true;
        }
    
        return false;
    }

    const handleChange = (inputPara, value) => {
        let novoEmpresaFilter = {
            razaoSocial: empresaFilter.razaoSocial,
            sigla: empresaFilter.sigla,
            tipoDocumento: empresaFilter.tipoDocumento,
            numDocumento: empresaFilter.numDocumento,
            situacaoCadastro: empresaFilter.situacaoCadastro
        }

        novoEmpresaFilter[inputPara] = retornaValorFiltro(inputPara, value);
        setEmpresaFilter(novoEmpresaFilter);
    };

    const handleClear = () => {
        setEmpresaFilter({
            razaoSocial: '',
            sigla: '',
            tipoDocumento: '',
            numDocumento: '',
            situacaoCadastro: ''
        });
    }

    const handleSearch = () => {
        setFiltrar(true);
    };

    return (
        <EmpresaFilterContext.Provider value={{ 
            empresaFilter, 
            handleChange, 
            filtrar, 
            setFiltrar, 
            handleSearch,
            verificaFiltroVazio,
            handleClear
        }}>
            {children}
        </EmpresaFilterContext.Provider>
    );
};

const retornaValorFiltro = (inputPara, filtro) => {

    let retorno = "";

    switch(inputPara) {
        case "razaoSocial":
            retorno = filtro;
            break;
        case "sigla":
            retorno = filtro;
            break;
        case "numDocumento":
            retorno = filtro;
            break;
        case "situacaoCadastro":
            retorno = filtro.idSituacaoCadastro;
            break;
        case "tipoDocumento":
            retorno = filtro.idTipoDocumento;
            break;
        default:
            retorno = "erro";
            break;
    };

    return retorno;
};