import api from "./api";
import { recuperarCache, salvarCache } from "./cacheHandler";

export const getStatus = async () => {
    try {
        const statusEncontrados = recuperarCache('status');
        if(statusEncontrados) {
            return statusEncontrados;
        }

        const response = await api.get("/status_");

        // Cacheando
        salvarCache('status', response.data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};