import { useContext, useEffect, useState } from 'react';
import HeaderContainers from '../HeaderContainers/HeaderContainers';
import './FormCadastroEmpresa.css'
import { MdAccountBalance, MdContactPage, MdDescription, MdError } from "react-icons/md";
import { IoChatboxSharp } from "react-icons/io5";
import InputPadrao from '../InputPadrao/InputPadrao';
import InputSelectPadrao from '../InputSelectPadrao/InputSelectPadrao';
import BotaoPadrao from '../BotaoPadrao/BotaoPadrao';
import { EmpresaContext } from '../../context/EmpresaContext';
import { getSituacaoCadastro } from '../../services/situacaoCadastroService';
import { getTipoDocumento } from '../../services/tipoDocumentoService';
import NotifyUtils from '../../utils/NotifyUtils';
import { useAuth } from '../../services/AuthContext';
import Loading from '../Loading/Loading';

function FormCadastroEmpresa() {

    const [ loading, setLoading ] = useState(true);

    const { user, setPodeCadastrarDepartamento } = useAuth();
    const { empresaData, handleChange, handleSubmit, handleCancelling } = useContext(EmpresaContext);

    if(!user.podeCadastrarEmpresa) {
        window.location.href = '/empresas';
    }

    const textoCarregando = "carregando...";
    const textoConfirmar = "confirmar";

    const [situacaoCadastro, setSituacaoCadastro] = useState(null);
    const [tipoDocumento, setTipoDocumento] = useState(null);
    const [botaoDesabilitado, setBotaoDesabilitado] = useState(false);
    const [mensagemBotaoConfirmar, setMensagemBotaoConfirmar] = useState(textoConfirmar);

    const [ cnpjValido, setCnpjValido ] = useState(true);
    const [ razaoSocialValida, setRazaoSocialValida ] = useState(true);
    const [ siglaValida, setSiglaValida ] = useState(true);
    const [ situacaoCadastroValida, setSituacaoCadastroValida ] = useState(true);

    useEffect(() => {
        async function fetchSituacao() {
            const situacaoCadastroFetched = await getSituacaoCadastro();
            setSituacaoCadastro(situacaoCadastroFetched);
        }

        async function fetchTipoDocumento() {
            const start = Date.now();
            const tipoDocumentoFetched = await getTipoDocumento();

            // Forçar um tempo mínimo de exibição de loading (ex: 500ms)
            const elapsed = Date.now() - start;
            const delay = Math.max(500 - elapsed, 0);
    
            setTimeout(() => {
                setTipoDocumento(tipoDocumentoFetched);
                setLoading(false);
            }, delay);
        }

        fetchSituacao();
        fetchTipoDocumento();

    }, []);

    const handleConfirmar = async (e) => {
        // Assim que confirmar, desabilita o botão e muda o texto para "carregando..."
        e.preventDefault();
        setBotaoDesabilitado(true);
        setMensagemBotaoConfirmar(textoCarregando)
        
        // Faz o envio de informações para o back caso esteja tudo certo com os campos, se não tiver, retorna os campos inválidos
        const retornoRequest = await handleSubmit();
        const erroCustom = retornoRequest.success === undefined;

        if(retornoRequest === null) {
            NotifyUtils.info("Cadastrando...");
            return;
        }

        if(!retornoRequest.success && !erroCustom) {
            NotifyUtils.erro(retornoRequest.message);
        } else if(retornoRequest.success) {
            NotifyUtils.sucesso(retornoRequest.message);
            NotifyUtils.sucesso("Redirecionando...");

            setCnpjValido(true);
            setRazaoSocialValida(true);
            setSiglaValida(true);
            setSituacaoCadastroValida(true);

            setTimeout(() => {
                handleCancelling();
                setBotaoDesabilitado(false);
                setMensagemBotaoConfirmar(textoConfirmar);
            }, 3500);

            setPodeCadastrarDepartamento(true);
            return;
        } else if(erroCustom) {
            NotifyUtils.erro(retornoRequest.message);

            // Atualiza status dos campos inválisdos
            const camposInvalidos = retornoRequest.camposInvalidos;

            setCnpjValido(camposInvalidos.cnpjValido);
            setRazaoSocialValida(camposInvalidos.razaoSocialValida);
            setSiglaValida(camposInvalidos.siglaValida);
            setSituacaoCadastroValida(camposInvalidos.situacaoCadastroValida);
        }

        setBotaoDesabilitado(false);
        setMensagemBotaoConfirmar(textoConfirmar);
    }

    const clickBotaoCancelar = (e) => {
        e.preventDefault();
        handleCancelling();
    }

    let tiposDeDocumento = [];
    let situacoesCadastro = [];

    if (situacaoCadastro !== null) {
        situacoesCadastro = situacaoCadastro;
    }

    if(tipoDocumento !== null) {
        tiposDeDocumento = tipoDocumento;
    }

    return (
        <div className="container-cadastrar-empresa">
            {loading && <Loading />}
            <div className="container-geral-cadastro-empresa">
                <HeaderContainers texto="cadastro de empresa" />
                <div className="formulario-cadastrar-nova-empresa">
                    <form>
                        <div className="container-razao-sigla">
                            <InputPadrao
                                onChange={handleChange} 
                                obrigatorio={true} 
                                titulo="RAZÃO SOCIAL" 
                                icone={<MdAccountBalance />} 
                                placeholder={'Insira a razão social de sua empresa.'}
                                inputPara={"razaoSocial"}
                                name={"razao-social"}
                                comErro={!razaoSocialValida}
                            />
                            <InputPadrao
                                onChange={handleChange} 
                                titulo="SIGLA" 
                                icone={<IoChatboxSharp />} 
                                placeholder={'Sigla da empresa.'}
                                inputPara={"sigla"}
                                name={"sigla"}
                                comErro={!siglaValida}
                            />
                        </div>

                        <div className="container-documentos-cadastro-empresa">
                            <div className="titulo-container-documentos">
                                <h3 className="titulo-documentos-cadastro-empresa">DOCUMENTOS</h3>
                                <hr className="divisor-top-container" />
                            </div>

                            <div className="container-inputs-selects">
                                <InputSelectPadrao
                                    onChange={handleChange}
                                    titulo="TIPO DE DOCUMENTO"
                                    icone={<MdContactPage />}
                                    obrigatorio={true}
                                    name={"tipo-documento"}
                                    placeholder={"Selecione o tipo"}
                                    options={tiposDeDocumento}
                                    inputPara={"tipoDocumento"}
                                    comErro={!cnpjValido}
                                />

                                <InputPadrao
                                    onChange={handleChange}
                                    titulo="NÚMERO DO DOCUMENTO"
                                    icone={<MdDescription />}
                                    obrigatorio={true}
                                    placeholder={"Insira o documento"}
                                    inputPara={"numDocumento"}
                                    name={"numero-documento-cad-empresa"}
                                    mask={empresaData.tipoDocumento.descricao}
                                    comErro={!cnpjValido}
                                />

                                <InputSelectPadrao
                                    onChange={handleChange}
                                    titulo="SITUAÇÃO DO CADASTRO"
                                    icone={<MdError />}
                                    obrigatorio={true}
                                    name={"situacao-cadastro"}
                                    placeholder={"Selecione a situação"}
                                    options={situacoesCadastro}
                                    inputPara={"situacaoCadastro"}
                                    comErro={!situacaoCadastroValida}
                                />
                            </div>
                        </div>

                        <div className="container-botoes-confirmar-cancelar-cadastro-empresa">

                            <BotaoPadrao 
                                acaoDeClicar={(e) => {clickBotaoCancelar(e)}}
                                texto="cancelar"
                                cancelar={true}
                                disabled={false}
                            />

                            <BotaoPadrao
                                confirmar={true}
                                acaoDeClicar={(e) => {handleConfirmar(e)}}
                                texto={mensagemBotaoConfirmar}
                                disabled={botaoDesabilitado}
                            />

                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default FormCadastroEmpresa;