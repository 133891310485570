// Importações iniciais
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './pages/Login';
import HomePage from './pages/Home';
import AbrirChamado from './pages/AbrirChamado';
import Empresas from './pages/Empresas';
import CadastroEmpresa from './pages/CadastroEmpresa';
import Usuarios from './pages/Usuarios';
import CadastroUsuario from './pages/CadastroUsuario';
import Departamentos from './pages/Departamentos';
import CadastroDepartamento from './pages/CadastroDepartamento';
import { AuthProvider, useAuth } from './services/AuthContext';
import PermissionsUtils from './utils/PermissionsUtils';
import NaoEncontrado from './pages/NaoEncontrado';
import EditarEmpresa from './pages/EditarEmpresa';
import EditarDepartamento from './pages/EditarDepartamento';
import EditarUsuario from './pages/EditarUsuario';
import VisualizarChamado from './pages/VisualizarChamado';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { limparTodosCaches } from './services/cacheHandler';

const admin = PermissionsUtils.admin;
const gestor = PermissionsUtils.gestor;

const PrivateRoute = ({ element, allowedRoles }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    limparTodosCaches('user');
    return <Navigate to="/login" />;
  }

  if (allowedRoles && !allowedRoles.includes(user.perfilUsuario.idPerfilUsuario)) {
    return <Navigate to="/" />;
  }

  return element;
};

function App() {
  return (
    <Router>
      {/* ToastContainer é um componente que exibe mensagens de alerta na tela */}
      <ToastContainer />

      <AuthProvider>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<PrivateRoute element={<HomePage />}/>}  />

          <Route
            path="/abrir-chamado"
            element={
              <PrivateRoute
              element={<AbrirChamado />} />}
          />

          <Route
            path="/visualizar-chamado/:idChamado"
            element={
              <PrivateRoute
              element={<VisualizarChamado />} />}
          />

          {/* <Route path="/favoritos" element={<PrivateRoute element={<Favoritos />}/>}  /> */}
          
          <Route 
            path="/empresas"
            element={
              <PrivateRoute
                element={<Empresas />}
                allowedRoles={[ admin ]} />}
          />

          <Route
            path="/cadastrar-empresa"
            element={
              <PrivateRoute
                element={<CadastroEmpresa />}
                allowedRoles={[ admin ]} />}
          />

          <Route
            path="/editar-empresa/:idEmpresa"
            element={
              <PrivateRoute
                element={<EditarEmpresa />}
                allowedRoles={[ admin ]} />}
          /> 

          <Route 
            path="/departamentos"
            element={
              <PrivateRoute
                element={<Departamentos />}
                allowedRoles={[ admin ]} />}
          />

          <Route
            path="/cadastrar-departamento"
            element={
              <PrivateRoute
                element={<CadastroDepartamento />}
                allowedRoles={[ admin ]} />}
          />

          <Route
            path="/editar-departamento/:idDepartamento"
            element={
              <PrivateRoute
                element={<EditarDepartamento />}
                allowedRoles={[ admin ]} />}
          />

          <Route 
            path="/usuarios"
            element={
              <PrivateRoute
                element={<Usuarios />}
                allowedRoles={[ admin, gestor ]} />}
          />

          <Route 
            path="/cadastrar-usuario"
            element={
              <PrivateRoute
                element={<CadastroUsuario />}
                allowedRoles={[ admin, gestor ]} />}
          />

          <Route 
            path="/editar-usuario/:idUsuario"
            element={
              <PrivateRoute
                element={<EditarUsuario />}
                allowedRoles={[ admin, gestor ]} />}
          />

          <Route path="*" element={<NaoEncontrado />} />

        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
