import { MdDescription, MdOutlineError } from 'react-icons/md';
import { FaSearch, FaPlus } from "react-icons/fa";
import HeaderContainers from '../HeaderContainers/HeaderContainers';
import InputPadrao from '../InputPadrao/InputPadrao';
import './FiltroDepartamentoContainer.css';
import InputSelectPadrao from '../InputSelectPadrao/InputSelectPadrao';
import BotaoCustom from '../BotaoCustom/BotaoCustom';
import { getSituacaoCadastro } from '../../services/situacaoCadastroService';
import { useContext, useEffect, useState } from 'react';
import { DepartamentoFilterContext } from '../../context/DepartamentoFilterContext';
import NotifyUtils from '../../utils/NotifyUtils';
import BotaoPadrao from '../BotaoPadrao/BotaoPadrao';
import { useAuth } from '../../services/AuthContext';

function FiltroDepartamentoContainer() {

    const { user } = useAuth();
    const { handleClear, handleSearch, handleChange } = useContext(DepartamentoFilterContext);

    // Função que direciona para a página de cadastro de departamento
    const direcionaParaCadastroDepartamento = () => {
        if(!user.podeCadastrarDepartamento) {
            NotifyUtils.aviso("É necessário cadastrar uma empresa antes de cadastrar um departamento!");
            return;
        }

        window.location.href = "/cadastrar-departamento";
    }

    const [situacaoCadastro, setSituacaoCadastro] = useState(null);
    useEffect(() => {
        getSituacaoCadastro().then((situacaoCadastro) => {
            setSituacaoCadastro(situacaoCadastro);
        }).catch((error) => {
            console.error(error);
        });
    }, []);

    let situacoesCadastro = [];
    if (situacaoCadastro !== null) {
        situacoesCadastro = situacaoCadastro;
    } else {
        return <div>Loading...</div>;
    }

    const limparFiltros = () => {
        NotifyUtils.sucesso("Filtros removidos com sucesso!");
        handleClear();
    };

    return (
        <div>
            <HeaderContainers texto="selecione os filtros" />

            <div className="container-filtro-departamentos">
                    <div className="container-inputs-filtro-departamento">
                        <InputPadrao
                            onChange={handleChange}
                            className={"input-nome-departamento"}
                            inputPara={"nomeDepartamento"}
                            titulo={"NOME DO DEPARTAMENTO"}
                            icone={<MdDescription />}
                            placeholder={"Insira um nome para o departamento (ex.: Desenvolvimento)."}
                            obrigatorio={false}
                            type={"text"}
                            name={"nome-departamento"}
                        />

                        <InputSelectPadrao
                            className={"input-situacao-cadastro-filtro-departamento"}
                            titulo={"SITUAÇÃO DO CADASTRO"}
                            obrigatorio={false}
                            name={"situacao-cadastro"}
                            inputPara={"situacaoCadastro"}
                            onChange={handleChange}
                            placeholder={"Selecione a situação"}
                            options={situacoesCadastro}
                            icone={<MdOutlineError />}
                        />
                    </div>

                    <div className="botoes-filtro-departamentos">
                        <BotaoPadrao
                            texto={"limpar filtros"}
                            icone={null}
                            acaoDeClicar={limparFiltros}
                        />

                        <BotaoCustom
                            acaoDeClicar={handleSearch}
                            icone={<FaSearch />}
                            texto={"pesquisar"}
                            cancelar={false}
                        />
                        
                        <BotaoCustom
                            acaoDeClicar={direcionaParaCadastroDepartamento}
                            icone={<FaPlus />}
                            texto={"cadastrar departamento"}
                            cancelar={true}
                        />
                    </div>
            </div>
        </div>
    )
}

export default FiltroDepartamentoContainer;