import { createContext, useState } from "react";

export const UsuarioFilterContext = createContext();

export const UsuarioFilterProvider = ({ children }) => {

    const [filtrar, setFiltrar] = useState(false);
    const [usuarioFilter, setUsuarioFilter] = useState({
        nome: '',
        email: '',
        cpf: '',
        situacaoCadastro: ''
    });

    const verificaFiltroVazio = () => {
        // Verifica se algum dos campos está vazio
        if(usuarioFilter.nome === '' 
            && usuarioFilter.email === ''
            && usuarioFilter.cpf === ''
            && usuarioFilter.situacaoCadastro === '') {
                return true;
        }
    
        return false;
    }

    const handleChange = (inputPara, value) => {
        let novoUsuarioFilter = {
            nome: usuarioFilter.nome,
            email: usuarioFilter.email,
            cpf: usuarioFilter.cpf,
            situacaoCadastro: usuarioFilter.situacaoCadastro,
        }

        novoUsuarioFilter[inputPara] = retornaValorFiltro(inputPara, value);

        setUsuarioFilter(novoUsuarioFilter);
    };

    const handleClear = () => {
        setUsuarioFilter({
            nome: '',
            email: '',
            cpf: '',
            situacaoCadastro: ''
        });
    }

    const handleSearch = () => {
        setFiltrar(true);
    };

    return (
        <UsuarioFilterContext.Provider value={{ 
            usuarioFilter, 
            handleChange, 
            filtrar, 
            setFiltrar, 
            handleSearch,
            verificaFiltroVazio,
            handleClear
        }}>
            {children}
        </UsuarioFilterContext.Provider>
    );
};

const retornaValorFiltro = (inputPara, filtro) => {

    let retorno = "";

    switch(inputPara) {
        case "nome":
            retorno = filtro;
            break;
        case "situacaoCadastro":
            retorno = filtro.idSituacaoCadastro;
            break;
        case "cpf":
            retorno = filtro.replace(/[^\d]+/g,'');
            break;
        case "email":
            retorno = filtro;
            break;
        default:
            retorno = "erro";
            break;
    };

    return retorno;
};