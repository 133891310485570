import { useAuth } from '../../services/AuthContext';
import './Header.css'

const logoImage = 'https://i.imgur.com/ZAjGAKN.jpeg';

function Header() {

  const { user } = useAuth();

  return (
    <div className="header">
        <div className="usuario-logado-container">
          <div className="info-usuario-logado">
            <h3>{user.nome}</h3>
            <p>{user.perfilUsuario.descricao}</p>
          </div>

          {/* Por enquanto sem imagem: <div className="img-usuario-logado">
            <img src={logoImage} alt="Logo do usuário logado" />
          </div> */}
        </div>
    </div>
  )
}

export default Header