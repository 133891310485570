import { MdAccountBalance, MdContactPage, MdDescription, MdOutlineError } from 'react-icons/md';
import { FaSearch, FaPlus } from "react-icons/fa";
import { IoChatbox } from "react-icons/io5";
import HeaderContainers from '../HeaderContainers/HeaderContainers';
import InputPadrao from '../InputPadrao/InputPadrao';
import './FiltroEmpresaContainer.css';
import InputSelectPadrao from '../InputSelectPadrao/InputSelectPadrao';
import BotaoCustom from '../BotaoCustom/BotaoCustom';
import BotaoPadrao from '../BotaoPadrao/BotaoPadrao';
import { EmpresaFilterContext } from '../../context/EmpresaFilterContext';
import { useContext, useEffect, useState } from 'react';
import { getSituacaoCadastro } from '../../services/situacaoCadastroService';
import { getTipoDocumento } from '../../services/tipoDocumentoService';
import NotifyUtils from '../../utils/NotifyUtils';

function FiltroEmpresaContainer({ podeCadastrar }) {

    const { handleClear, empresaFilter, handleChange, handleSearch } = useContext(EmpresaFilterContext);

    const limparFiltros = () => {
        NotifyUtils.sucesso("Filtros removidos com sucesso!");
        handleClear();
    };

    const [situacaoCadastro, setSituacaoCadastro] = useState(null);
    useEffect(() => {
        getSituacaoCadastro().then((situacaoCadastro) => {
            setSituacaoCadastro(situacaoCadastro);
        }).catch((error) => {
            console.error(error);
        });
    }, []);

    const [tipoDocumento, setTipoDocumento] = useState(null);
    useEffect(() => {
        getTipoDocumento().then((tipoDocumento) => {
            setTipoDocumento(tipoDocumento);
        }).catch((error) => {
            console.error(error);
        });
    }, []);

    let situacoesCadastro = [];
    let tipoDocumentos = [];

    if (situacaoCadastro !== null) {
        situacoesCadastro = situacaoCadastro;
    } else {
        return <div>Loading...</div>;
    }

    if (tipoDocumento !== null) {
        tipoDocumentos = tipoDocumento;
    } else {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <HeaderContainers texto="selecione os filtros" />

            <div className="container-filtro-empresas">

                <div className="container-razao-sigla-endereco">
                    <InputPadrao
                        className={"input-razao-social-filtro-empresa"}
                        titulo={"RAZÃO SOCIAL"}
                        icone={<MdAccountBalance />}
                        placeholder={"Insira a razão social da empresa."}
                        obrigatorio={false}
                        type={"text"}
                        name={"razao-social"}
                        onChange={handleChange}
                        inputPara={"razaoSocial"}
                    />

                    <InputPadrao
                        className={"input-sigla-filtro-empresa"}
                        titulo={"SIGLA"}
                        icone={<IoChatbox />}
                        placeholder={"Escreva a sigla da empresa."}
                        obrigatorio={false}
                        type={"text"}
                        name={"sigla"}
                        onChange={handleChange}
                        inputPara={"sigla"}
                    />

                    <div className="flex-blank"></div>
                </div>

                <div className="container-low-filtro-empresas">

                    <div className="container-documentos-e-situacao-filtro-empresas">
                        <InputSelectPadrao
                            className={"input-tipo-documento-filtro-empresa"}
                            titulo={"TIPO DE DOCUMENTO"}
                            obrigatorio={false}
                            name={"tipo-documento"}
                            inputPara={"tipoDocumento"}
                            placeholder={"Selecione o tipo"}
                            options={tipoDocumentos}
                            icone={<MdContactPage />}
                            onChange={handleChange}
                        />

                        <InputPadrao
                            className={"input-documento-filtro-empresa"}
                            inputPara={"numeroDocumento"}
                            titulo={"NÚMERO DO DOCUMENTO"}
                            icone={<MdDescription />}
                            placeholder={"Insira o número do documento."}
                            obrigatorio={false}
                            type={"text"}
                            name={"numero-documento"}
                            onChange={handleChange}
                        />

                        <InputSelectPadrao
                            className={"input-situacao-cadastro-filtro-empresa"}
                            titulo={"SITUAÇÃO DO CADASTRO"}
                            obrigatorio={false}
                            name={"situacao-cadastro"}
                            inputPara={"situacaoCadastro"}
                            placeholder={"Selecione a situação"}
                            options={situacoesCadastro}
                            onChange={handleChange}
                            icone={<MdOutlineError />}
                            sendAll={true}
                        />

                        <div className="flex-grow-big"></div>
                    </div>

                    <div className="botoes-filtro-empresas">

                        <BotaoPadrao
                            texto={"limpar filtros"}
                            icone={null}
                            acaoDeClicar={limparFiltros}
                        />

                        <BotaoCustom
                            acaoDeClicar={handleSearch}
                            icone={<FaSearch />}
                            texto={"pesquisar"}
                            cancelar={false}
                        />

                        {podeCadastrar && (
                            <BotaoCustom
                                redirecionar={"/cadastrar-empresa"}
                                icone={<FaPlus />}
                                texto={"cadastrar empresa"}
                                cancelar={true}
                            />
                        )}
                        
                    </div>
                </div>

            </div>
        </div>
    )
}

export default FiltroEmpresaContainer;