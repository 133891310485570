// Função para verificar para qual tipo de filtro o usuário está pesquisando
export const verificaTipoFiltro = (filtro) => {
    if (filtro.startsWith("#")) {
        return "idChamado";
    } else {
        return "assunto";
    }
};

export const retornaFiltro = (filtro) => {

    const filtrando = filtro.toLowerCase()

    if(filtrando === "" || filtrando === undefined || filtrando === null) {
        return "";
    }

    if(filtrando.startsWith("#")) {
        return filtrando.substring(1);
    }

    return filtrando;
};