import HeaderContainers from '../HeaderContainers/HeaderContainers';
import InputPadrao from '../InputPadrao/InputPadrao';
import './FiltroUsuarioContainer.css';
import { FaUser } from "react-icons/fa";
import BotaoCustom from '../BotaoCustom/BotaoCustom';
import { FaSearch, FaPlus } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { IoDocumentTextSharp } from "react-icons/io5";
import InputSelectPadrao from '../InputSelectPadrao/InputSelectPadrao';
import { UsuarioFilterContext } from '../../context/UsuarioFilterContext';
import { useContext, useEffect, useState } from 'react';
import { getSituacaoCadastro } from '../../services/situacaoCadastroService';
import BotaoPadrao from '../BotaoPadrao/BotaoPadrao';
import NotifyUtils from '../../utils/NotifyUtils';
import { useAuth } from '../../services/AuthContext';

function FiltroUsuarioContainer() {

  const { user } = useAuth();
  const { handleClear, handleSearch, handleChange } = useContext(UsuarioFilterContext);

  // Função que direciona para a página de cadastro de usuário
  const direcionaParaCadastroUsuario = () => {
    if(!user.podeCadastrarUsuario) {
        NotifyUtils.aviso("É necessário cadastrar um departamento antes de cadastrar um usuário!");
        return;
    }

    window.location.href = "/cadastrar-usuario";
}

  const [situacaoCadastro, setSituacaoCadastro] = useState(null);
  useEffect(() => {
      getSituacaoCadastro().then((situacaoCadastro) => {
          setSituacaoCadastro(situacaoCadastro);
      }).catch((error) => {
          console.error(error);
      });
  }, []);

  let situacoesCadastro = [];
  if (situacaoCadastro !== null) {
      situacoesCadastro = situacaoCadastro;
  } else {
      return <div>Loading...</div>;
  }

  const limparFiltros = () => {
    NotifyUtils.sucesso("Filtros removidos com sucesso!");
    handleClear();
  };

  return (
    <div>
        <HeaderContainers texto="selecione os filtros" />

        <div className="container-filtro-usuarios">

          <div className="inputs-principal-filtro-usuario">
            <InputPadrao 
              className={"input-nome-filtro-usuario"}
              inputPara={"nome"}
              titulo={"NOME"}
              icone={<FaUser />}
              placeholder={"Insira o nome do usuário."}
              obrigatorio={false}
              type={"text"}
              name={"nome"}
              onChange={handleChange}
            />

            <InputPadrao
              className={"input-email-filtro-usuario"}
              titulo={"EMAIL"}
              inputPara={"email"}
              icone={<IoMdMail />}
              placeholder={"Insira o email do usuário."}
              obrigatorio={false}
              type={"text"}
              name={"email"}
              onChange={handleChange}
            />

            <InputPadrao
              className={"input-cpf-filtro-usuario"}
              titulo={"NÚMERO DO CPF"}
              inputPara={"cpf"}
              icone={<IoDocumentTextSharp />}
              placeholder={"Insira o CPF do usuário."}
              obrigatorio={false}
              type={"text"}
              name={"cpf"}
              mask={"cpf"}
              onChange={handleChange}
            />

            <InputSelectPadrao
              className={"input-situacao-cadastro-filtro-usuario"}
              titulo={"SITUAÇÃO DO CADASTRO"}
              inputPara={"situacaoCadastro"}
              icone={<IoDocumentTextSharp />}
              obrigatorio={false}
              name={"situacao-cadastro"}
              options={situacoesCadastro}
              onChange={handleChange}
            />
          </div>

          <div className="botoes-filtro-usuarios">
            <BotaoPadrao
              texto={"limpar filtros"}
              icone={null}
              acaoDeClicar={limparFiltros}
            />

            <BotaoCustom
                acaoDeClicar={handleSearch}
                icone={<FaSearch />}
                texto={"pesquisar"}
                cancelar={false}
            />
            
            <BotaoCustom
              acaoDeClicar={direcionaParaCadastroUsuario}
              icone={<FaPlus />}
              texto={"cadastrar usuario"}
              cancelar={true}
            />
          </div>
        </div>
    </div>
  )
}

export default FiltroUsuarioContainer