import api from "./api";
import { recuperarCache, salvarCache } from "./cacheHandler";

export const getSituacaoCadastro = async () => {

    // Verificando o cache
    const situacoesCadastro = recuperarCache('situacaoCadastro');
    if (situacoesCadastro) {
        return situacoesCadastro;
    }

    try {
        const response = await api.get("/situacaoCadastro_");
        
        // Salvando no cache
        salvarCache('situacaoCadastro', response.data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}